import React from "react";
import "../styles/scrim.css";

export default function Scrim({ id, center, dismisable = false, children }) {
  const classes = center === "text" ? "centerText" : "";

  function handleClick(e) {
    if (dismisable && e.target.id === id) {
      document.getElementById(id).classList.remove("show");
    }
  }

  return (
    <div className="center scrim" id={id} onClick={handleClick}>
      <div className={classes}>{children}</div>
    </div>
  );
}
