import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Content from "../components/Content";
import Input from "../components/Input";
import Button from "../components/Button";

export default function AccountEdit() {
  const { currentUser, updatePassword, updateEmail, updateProfile } = useAuth();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState(currentUser.displayName);
  const [email, setEmail] = useState(currentUser.email);
  const history = useHistory();

  // TODO: Reauth if not a recent sign in
  // https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
  // https://stackoverflow.com/questions/39087446/firebase-recent-login-requested

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (displayName !== currentUser.displayName) {
      promises.push(updateProfile(displayName));
    }
    if (email !== currentUser.email) {
      promises.push(updateEmail(email));
    }
    if (password) {
      promises.push(updatePassword(password));
    }

    Promise.all(promises)
      .then(() => {
        history.push("/account");
      })
      .catch((error) => {
        console.log(error.message);
        if (
          error.message ===
          "This operation is sensitive and requires recent authentication. Log in again before retrying this request."
        ) {
          setError(error.message);
        } else if (
          error.message ===
          "The email address is already in use by another account."
        ) {
          setError(
            "The email address is already in use by another account. Contact support if you wish to delete the other account."
          );
        } else {
          setError("Failed to update account");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = "Edit account login - Simplify Gmail";
  }, []);

  return (
    <Content center="text">
      <p className="heading">Edit account login</p>
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit} className="centerText">
        <label>Name</label>
        <Input
          type="text"
          id="name"
          required
          value={displayName}
          placeholder="Name"
          handleChange={(e) => setDisplayName(e.target.value)}
        />
        <br />
        <br />
        <label>Login email</label>
        <Input
          type="email"
          id="email"
          required
          value={email}
          placeholder="Email"
          handleChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <label>Password (optional)</label>
        <Input
          type="password"
          id="password"
          value={password}
          placeholder="Leave blank to not change"
          handleChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          id="password-confirm"
          value={confirmPassword}
          placeholder="Confirm password if changed"
          handleChange={(e) => setConfirmPassword(e.target.value)}
        />
        <br />
        <br />
        <Button type="submit" disabled={loading}>
          Update
        </Button>
        <p>
          <Link to="/account">Cancel</Link>
        </p>
      </form>
    </Content>
  );
}
