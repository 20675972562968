import React from "react";
import "../styles/input.css";

const Input = ({ handleChange, label, ...otherProps }) => {
  return (
    <>
      {label && <label>{label}</label>}
      <input className="smpl" onChange={handleChange} {...otherProps} />
    </>
  );
};

export default Input;
