import React, { useContext, useState, useEffect } from "react";
import { auth, db, GoogleProvider } from "../utils/firebase";
import {
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile as fbUpdateProfile,
  updateEmail as fbUpdateEmail,
  updatePassword as fbUpdatePassword,
} from "firebase/auth";
import { update, push, set, onValue } from "firebase/database";
import hash from "../utils/hash";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(true);
  let accountRef;

  function getLoginMethod(email) {
    return fetchSignInMethodsForEmail(auth, email);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function loginWithGoogle(email = "") {
    if (email !== "") GoogleProvider.setCustomParameters({ login_hint: email });
    return signInWithPopup(auth, GoogleProvider);
  }

  function loginWithEmailStart(email, url) {
    window.localStorage.setItem("emailForLogin", email);
    return sendSignInLinkToEmail(auth, email, {
      url: url,
      handleCodeInApp: true,
    });
  }

  function loginWithEmailFinish(email, href) {
    return signInWithEmailLink(auth, email, href);
  }

  function isLoginLink(href) {
    return isSignInWithEmailLink(auth, href);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateProfile(name) {
    return fbUpdateProfile(currentUser, { displayName: name });
  }

  function updateEmail(email) {
    return fbUpdateEmail(currentUser, email);
  }

  function updatePassword(password) {
    return fbUpdatePassword(currentUser, password);
  }

  function initSubscription(sid) {
    const updates = {
      [`index/subs/${sid}`]: currentUser.uid,
      [`users/${currentUser.uid}/subs/${sid}/renew`]: true,
    };
    return update(db(), updates);
  }

  function addPerson(sid, name) {
    return push(db(`users/${currentUser.uid}/subs/${sid}/people`), {
      name: name,
    });
  }

  function updateName(sid, pid, name) {
    return set(db(`users/${currentUser.uid}/subs/${sid}/people/${pid}/name`), name);
  }

  function deletePerson(sid, pid, emails) {
    const updates = {
      [`users/${currentUser.uid}/subs/${sid}/people/${pid}`]: null,
    };
    if (emails) {
      emails.forEach((emailHash) => {
        updates[`emails/${emailHash}`] = null;
        updates[`index/emails/${emailHash}`] = null;
      });
    }
    return update(db(), updates);
  }

  function addEmail(sid, pid, email, expires) {
    const emailHash = hash(email);
    const updates = {
      [`users/${currentUser.uid}/subs/${sid}/people/${pid}/emails/${emailHash}`]: email,
      [`index/emails/${emailHash}/${currentUser.uid}/pid`]: pid,
      [`index/emails/${emailHash}/${currentUser.uid}/sid`]: sid,
      [`emails/${emailHash}`]: expires,
    };
    return update(db(), updates);
  }

  function deleteEmail(sid, pid, email) {
    const emailHash = hash(email);
    const updates = {
      [`users/${currentUser.uid}/subs/${sid}/people/${pid}/emails/${emailHash}`]: null,
      [`index/emails/${emailHash}/${currentUser.uid}`]: null,
      [`emails/${emailHash}`]: null,
    };
    return update(db(), updates);
  }

  function loadAccount() {
    if (accountRef === undefined) {
      accountRef = onValue(db(`users/${currentUser.uid}`), (snapshot) => {
        if (snapshot.exists()) {
          const accountData = snapshot.val();
          // First set the account to empty so the components detect a state change and rerender
          setAccount({});
          setAccount(accountData);
        } else {
          // Did not find an account
          setAccount({});
        }
      });
    }
  }

  // Update currentUser when auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const exports = {
    currentUser,
    account,
    getLoginMethod,
    login,
    loginWithGoogle,
    loginWithEmailStart,
    loginWithEmailFinish,
    isLoginLink,
    logout,
    resetPassword,
    updateProfile,
    updateEmail,
    updatePassword,
    loadAccount,
    initSubscription,
    addPerson,
    updateName,
    deletePerson,
    addEmail,
    deleteEmail,
  };

  return <AuthContext.Provider value={exports}>{!loading && children}</AuthContext.Provider>;
}
