import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Content from "../components/Content";
import Loading from "../components/Loading";
import Subscriptions from "../components/Subscriptions";
import "../styles/account.css";

export default function Account() {
  const { account, loadAccount, logout } = useAuth();
  const history = useHistory();

  function hasActiveSubscription() {
    return (
      account?.subs &&
      Object.values(account?.subs).filter((sub) => {
        return sub.active || (!sub.active && sub.product === undefined);
      }).length > 0
    );
  }

  function handleSwitchAccounts() {
    logout()
      .then(() => {
        history.push("/login");
      })
      .catch((error) => {
        console.log("Failed to log out", error.message);
      });
  }

  // Call to get account info on load
  useEffect(() => {
    loadAccount();
    document.title = "Manage plan - Simplify Gmail";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading />
      <Content>
        {hasActiveSubscription() ? (
          <Subscriptions subscriptions={account.subs} fs={account.fs} />
        ) : (
          <>
            <h2>No active plan found</h2>
            <ul>
              <li>
                If you just signed up, wait a moment for your plan to activate.
              </li>
              <li>
                <Link to="/plans">Sign up</Link> for a plan if you haven't
                already.
              </li>
              <li>
                <span className="link" onClick={handleSwitchAccounts}>
                  Switch accounts
                </span>{" "}
                if you signed up with another email (only that account can
                manage the plan).
              </li>
              <li>
                <Link to="/support">Contact support</Link> if you think there is
                an error.
              </li>
            </ul>
          </>
        )}
      </Content>
    </>
  );
}
