import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import Content from "../components/Content";
import Input from "../components/Input";
import Button from "../components/Button";

export default function Recover() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(email);
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  useEffect(() => {
    document.title = "Recover account for Simplify Gmail";
  }, []);

  return (
    <Content center="text">
      <h2>Reset password</h2>
      {error && <div>{error}</div>}
      {message && <div>{message}</div>}
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          id="email"
          required
          value={email}
          placeholder="Email"
          handleChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" disabled={loading}>
          Reset password
        </Button>
        <p>
          <Link to="/login">Login</Link> • <Link to="/plans">Sign up</Link>
        </p>
      </form>
    </Content>
  );
}
