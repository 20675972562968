import React from "react";
import Scrim from "./Scrim";
import "../styles/loading.css";

export default function Loading() {
  return (
    <Scrim id="loading">
      <div className="spinner">Loading...</div>
    </Scrim>
  );
}
