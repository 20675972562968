/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect } from "react";
import Content from "../components/Content";
import Footer from "../components/Footer";

// CSS
import "../styles/home.css";

export default function Home() {
  // TODO: Componentize this?
  useEffect(() => {
    document.title = "Simplify Gmail: Transform Gmail into a premium experience";
  }, []);

  return (
    <>
      <Content>
        {/* TOC ---------------------------------------------------- */}
        <h2>About Simplify</h2>
        <ul className="TOC">
          <li>
            <a href="#betterGmail">What's it do</a> - Specific ways Simplify makes Gmail better.
          </li>
          <li>
            <a href="#mission">Mission</a> - The big picture.
          </li>
          <li>
            <a href="#purpose">Purpose</a> – What's the problem?
          </li>
          <li>
            <a href="#product">Product</a> - How does it solve this problem?
          </li>
          <li>
            <a href="#customer">Customer</a> - Who is this for?
          </li>
          <li>
            <a href="#pricing">Pricing</a> - Fair, simple, and flexible.
          </li>
          <li>
            <a href="#team">Team</a> – Who is behind Simplify?
          </li>
          <li>
            <a href="/privacy">Privacy Policy & TOS</a> – Private, secure, and safe.
          </li>
        </ul>

        {/* HOW DOES SIMPLIFY MAKE GMAIL BETTER? ------------------------------------------ */}
        <a name="betterGmail"></a>
        <h2>How does Simplify make Gmail better?</h2>
        <p>
          Simplify Gmail is a browser extension for desktop Gmail that <b>boosts productivity</b>,{" "}
          <b>strengthens privacy</b>, and <b>reduces️ stress</b> by:
        </p>
        <ul>
          <li>
            <b>A better design</b>: Hundreds of improvements (small and large) to streamline, simplify, and enhance
            Gmail’s design and functionality. Hide the features you don’t use, customize the ones you do including
            setting the list and message width and fonts.
          </li>
          <li>
            <b>Bundles</b>: Group conversations with the same label or category into a single row in your inbox for
            easier bulk reading and processing.{" "}
            <a href="https://www.youtube.com/embed/f_jel2PY9O8?autoplay=1&rel=0&showinfo=0&vq=hd1080" target="_new">
              Video demo
            </a>
          </li>
          <li>
            <b>Saved searches</b>: Add commonly used searches or bookmark any message to your nav for easy access.
            Simplify includes 24 useful searches by default like Travel, Purchases, Archived, Attachments, Notes to
            self, PDFs, Photos, and more.
          </li>
          <li>
            <b>Pause inbox</b>: Pause new mail or hide the inbox entirely for deep focus. Optionally disable
            notifications when the inbox is paused or hidden.
          </li>
          <li>
            <b>Block trackers</b>: Simplify blocks over 250 email spy trackers (more than any other tracker blocker
            extension).{" "}
            <a href="https://on.simpl.fyi/p/email-trackers-are-spying-on-yousimplify" target="_new">
              Learn more
            </a>
          </li>
          <li>
            <b>Keyboard shortcuts</b>: Navigate faster with your arrow keys, Enter (to open, then to reply), and Escape
            (close a message, then to return to inbox). Use familiar shortcuts like Ctrl+A / ⌘A to select all or Ctrl+Z
            / ⌘Z to undo. Press "?" in Gmail to see all shortcuts. Also includes advanced shortcuts like [ I ] to reply
            to an intro and move the sender to BCC.
          </li>
          <li>
            <b>Dark mode</b>: Auto-switch with OS with options to invert all of Gmail’s UI and some or all messages.
          </li>
          <li>
            <b>Group by date</b>: Group messages list by date with bulk actions to one-click archive or delete.
          </li>
          <li>
            <b>Inbox zero</b>: Immerse yourself in a background of your choice or a background of the day to get when
            you reach inbox zero.
          </li>
          <li>
            <b>Auto-bcc</b>: Set addresses to add to every new message and/or reply
          </li>
          <li>
            <b>And much more...</b>
          </li>
        </ul>
        <p>Read on to learn more about the philosophy behind the company and product.</p>

        {/* MISSION ---------------------------------------------------- */}
        <a name="mission"></a>
        <h2>Simplify's Mission</h2>
        <p>Make existing products simpler, more capable, and more respectful.</p>

        {/* PURPOSE ---------------------------------------------------- */}
        <a name="purpose"></a>
        <h2>Why does Simplify exist?</h2>
        <p>
          <b>Bad design is everywhere</b>. I’m equating bad design with anything that makes a product harder to use or
          less user-centric. Bad design can occur for a number of reasons including but not limited to:
        </p>
        <ul>
          <li>Our needs as users are not well understood, prioritized, or aligned with the company’s goals.</li>
          <li>
            Entropy: The natural decline of products over time as the vision decays or blurs and new features are
            conceived without consideration of the whole and added faster than the system’s overall design and
            architecture can evolve to support them.
          </li>
          <li>
            Good design is hard. Good design is more than making a product pretty. It is about having the right
            capabilities in an intuitive, respectful, and well-crafted offering. I hope to expand on this topic in
            future posts.
          </li>
        </ul>
        <p>
          <b>Bad design has real costs</b>. It costs us our productivity, our attention, our sanity, our privacy, and
          more. The{" "}
          <a href="https://ledger.humanetech.com/" target="_new">
            Center for Humane Technology
          </a>{" "}
          puts it this way:
        </p>
        <blockquote>
          Even with the best intentions, social media companies are under immense pressure to prioritize engagement and
          growth. This creates a race for human attention that has unleashed invisible harms in society.
        </blockquote>
        <p>
          Most companies are under this pressure, not just social media companies. I should be able to read my email
          without having to ignore a sea of unread notifications and new feature promotions or{" "}
          <a href="https://on.simpl.fyi/p/email-trackers-are-spying-on-yousimplify" target="_new">
            {" "}
            worrying about email trackers spying on me
          </a>
          .
        </p>
        <p>
          As users, we are at a supreme disadvantage in the battle for our attention. Our time, attention, productivity,
          and joy are invaluable and they are worth fighting for.
        </p>
        <p>
          <b>As users, we can’t fix bad design (until now)</b>
        </p>
        <ul>
          <li>Sending feedback just feels like screaming into the wind.</li>
          <li>We can rarely change software we use on our own.</li>
          <li>
            Even when we can modify our apps, doing so takes time and expertise and those modifications may break over
            time if not well supported.
          </li>
          <li>
            We can “vote with our feet” and switch products, but there isn’t always a better option or we’re locked into
            our current option.
          </li>
        </ul>

        {/* PRODUCT ---------------------------------------------------- */}
        <a name="product"></a>
        <h2>What is Simplify?</h2>
        <p>
          Simplify is a unique approach to solving bad design. It is software (browser extensions for now) that modifies
          the products you already use. While browser extensions that modify apps aren’t new, there aren’t many that are
          high quality, well maintained, and opinionated.
        </p>
        <p>
          <b>Other ways to solve bad design</b>
        </p>
        <p>
          The obvious ways to tackle the above problem is from the inside.{" "}
          <a href="https://linkedin.com/in/leggett">I’ve tried that</a>. As much as I loved Google Inbox (a product and
          team I co-founded but left before launch), what launched was a well executed and polished but watered-down
          version of the original vision.
        </p>
        <p>
          It isn’t that the teams behind these products don’t care. They care deeply. But big products have big teams
          and are often inside big companies with their own bigger (and often unclear) priorities. It is messy.
        </p>
        <p>Another option is to build a startup that creates a competing product. This might work but…</p>
        <ol>
          <li>Products like Gmail are actually pretty good already.</li>
          <li>
            Replacing them entirely would be a lot more work which would make the product more expensive. And while it
            may be better in some areas, it will likely be worse in others (see #1) meaning you are paying more and
            possibly getting less overall.
          </li>
          <li>
            Shipping a new product asks more of you, the user. I don’t want to ask you to change what product you use or
            migrate your data.
          </li>
          <li>
            Finally, I want to push the big companies to do better by showing them how their products can be better. It
            is easier for big companies to dismiss small competitors as niche products that don’t consider the full
            complexity of their product. What if the upstart
            <em>is</em> their product?
          </li>
        </ol>

        {/*-- WHO ----------------------------------------------------*/}
        <a name="customer"></a>
        <h2>Who is Simplify for?</h2>
        <p>
          I’m fighting for everyone but focused on people that fit any of the following profiles (all of which fit for
          me):
        </p>
        <ol>
          <li>
            <b>Maximizers</b>: Those that seek to be the most productive in using an app.
          </li>
          <li>
            <b>Form </b>
            <em>
              <b>and</b>
            </em>
            <b> function</b>: Those that value really good design over good-enough design without having to sacrifice
            robust capabilities.
          </li>
          <li>
            <b>Privacy-concerned</b>: Those that care about privacy. Privacy isn’t about keeping everything private (an
            email app where you can’t send or receive messages?) — it is about you having control over what is shared
            and how it is used. And exercising that control shouldn’t be a lot of work.
          </li>
          <li>
            <b>Distracted</b>: Those that have a hard time staying focused and productive in a noisy interface, or in a
            product engineered to keep us glued when we want to move on.
          </li>
        </ol>

        {/*-- PRICING ----------------------------------------------------*/}
        <a name="pricing"></a>
        <h2>Pricing</h2>
        <p>
          I’ve tried to keep the price as low as possible to maximize the affordability of Simplify. Keep in mind that
          fees and taxes eat up nearly half of a monthly payment and over a third of an annual payment.
        </p>
        <ul>
          <li>
            <b>$2/mo/person (paid annually)</b> for an individual, and $1/mo/person for each additional person on a
            plan. Paying monthly costs $1 more per month to cover the additional fees. The per-person price goes down as
            you add more people to the plan (once after 10 people and again after 25 people).
          </li>
          <li>
            <b>Per-person pricing:</b> Each person on a plan can use Simplify on unlimited computers/browsers and on up
            to 10 of their email accounts. Email accounts can be any mix of @gmail.com accounts and custom domain Google
            Workspace accounts.
          </li>
          <li>
            <b>Auto-renewal is easy to disable</b> for annual plans, and everyone gets notifications before any
            auto-renewal for those who elect to keep it enabled.
          </li>
          <li>
            <b>Can I get it for free? Maybe.</b> I’d much rather pay you for successful referrals than pay Facebook to
            spam people with ads. I'm still working out the details but, with enough referrals, Simplify Gmail could be
            free.
          </li>
        </ul>

        {/*-- TEAM ----------------------------------------------------*/}
        <a name="team"></a>
        <h2>Team</h2>
        <p>
          Simplify is made and maintained by{" "}
          <a href="https://leggett.org" target="_new">
            Michael Leggett
          </a>
          . Michael was Gmail's design lead from 2008 to 2012 and a co-founder and design lead for Google Inbox. With
          the unfortunate demise of Google Inbox, Leggett wanted bring the simplified email experience to Gmail. Thus,
          Simplify Gmail was born on April 2, 2019.
        </p>
        <p>
          Simplify is not affiliated with Google in any way. Gmail is a trademark of Google Inc. Use of this trademark
          is subject to Google Permissions.
        </p>
      </Content>
      <Footer />
    </>
  );
}
