import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, ref, child } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
// import { getAnalytics } from "firebase/analytics";

// PROD
let prodConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(prodConfig);

// Analaytics
// export const analytics = app.analytics();

// Auth
export const auth = getAuth(app);
export const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });

// Database
const database = getDatabase(app);
export const db = (path, childPath) => {
  if (path && childPath) {
    return child(ref(database, path), childPath);
  } else if (path) {
    return ref(database, path);
  } else {
    return ref(database);
  }
};

// Functions
const functions = getFunctions(app);

export { app, functions, httpsCallable };
