import React from "react";
import "../styles/footer.css";

export default function Footer() {
  return (
    <footer>
      <div>
        Copyright ©2024 Made Simple, LLC - <a href="/privacy">Privacy Policy & Terms of Service</a>
      </div>
    </footer>
  );
}
