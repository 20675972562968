import React, { useEffect } from "react";
import Content from "../components/Content";
// import ReportIssue from "../assets/images/report_issue.png";

// CSS
import "../styles/support.css";

export default function Support() {
  const init = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let system = urlParams.get("system");

    if (!system) {
      document.querySelectorAll(".hidden").forEach((el) => {
        el.classList.remove("hidden");
      });

      // Create reduced system report here
      system =
        `System: ${navigator.userAgent} - Window: ` +
        `${window.outerWidth} x ${window.outerHeight} (` +
        `${Math.round((window.outerWidth / window.innerWidth) * 100)}` +
        `pct zoom) - Language: ${navigator.language}`.replace(/;/g, "");
    }

    const body =
      "Please describe the issue and the steps to reproduce it. Screenshots are useful.%0D%0A%0D%0A---%0D%0A" + system;

    // document.querySelector("#email").href = "mailto:hi@simpl.fyi?body=" + body;
    document.querySelector("#email").href = "https://mail.google.com/mail/?fs=1&tf=cm&to=hi@simpl.fyi&body=" + body;

    document.querySelector("#form").href =
      "https://docs.google.com/forms/d/e/1FAIpQLSfdP7AV-LlankOxrNe9t45KsriaxxB0Ef2owkv-TZmATHU5wQ/viewform?usp=pp_url&entry.655050101=" +
      system;

    document.querySelector("#github").href = "https://issues.simpl.fyi/new?body=" + body;
  };

  useEffect(() => {
    init();
    document.title = "Report issue with Simplify Gmail";
  }, []);

  return (
    <Content center="text">
      <div className="hidden">
        <p className="warning">
          <b>⚠️ No system report attached.</b>
          <br />
          If your issue is related to the extension not working as expected, a system report is very helpful. To attach
          one, start an issue report from{" "}
          <a href="https://mail.google.com/mail/?simplify=debug" target="_new">
            Simplify preferences in Gmail.
          </a>
        </p>
      </div>

      <p className="heading">Report an issue with Simplify Gmail</p>
      <p>
        Thank you in advance for letting us know of any problems you are having with Simplify Gmail. Common issues are
        from Gmail making changes (which it does often), a{" "}
        <a href="https://on.simpl.fyi/p/complexity-of-simplify" target="_new">
          unique configuration
        </a>{" "}
        we have not tested, or a conflict with{" "}
        <a href="https://github.com/leggett/simplify/issues/554" target="_new">
          another extension
        </a>
        . You can report an issue using which ever option below you are most comfortable with.
      </p>
      <br />
      <br />
      <a href="/support" id="email" className="buttonSupport">
        Email
      </a>
      <a href="/support" id="form" className="buttonSupport">
        Google Form
      </a>
      <a href="/support" id="github" className="buttonSupport">
        GitHub issue
      </a>
    </Content>
  );
}
