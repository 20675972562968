import React from "react";
import "../styles/content.css";

export default function Content({ center, browser, children, ...otherProps }) {
  const classes = center === "text" ? "content centerText" : "content";
  const dataBrowser = browser ? browser : "";

  return (
    <div className={classes} data-browser={dataBrowser}>
      {children}
      {/* <div className="content center"><div className="centerText">{children}</div></div> */}
    </div>
  );
}
