import React from "react";
import "../styles/buttonIcon.css";

const ButtonIcon = ({ addClass, children, ...otherProps }) => {
  const classNames = `icon ${addClass}`;

  return (
    <button className={classNames} {...otherProps}>
      {children}
    </button>
  );
};

export default ButtonIcon;
