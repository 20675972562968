import React, { useState, useEffect } from "react";
import Content from "../components/Content";
import "../styles/install.css";

export default function Gmail() {
  const [browser, setBrowser] = useState("chrome");

  useEffect(() => {
    document.title = "Install Simplify Gmail";

    // User agent for sniffing browsers
    const UA = navigator.userAgent;

    const is = {
      safari: / Safari/.test(UA) && !/Chrome/.test(UA),
      firefox: / Firefox/.test(UA),
      edge: / Edg/.test(UA),
      brave: navigator.brave !== undefined,
    };

    if (is.safari) {
      setBrowser("safari");
    } else if (is.firefox) {
      setBrowser("firefox");
    } else if (is.edge) {
      setBrowser("edge");
    } else if (is.brave) {
      setBrowser("brave");
    }
  }, []);

  return (
    <Content center="text" browser={browser}>
      <h2>Install Simplify Gmail</h2>

      <p className="mobileWarning">⚠️ Simplify Gmail does not work on mobile devices</p>

      {/* MAIN INSTALL BUTTON */}
      <a
        className="button chrome"
        href="https://chrome.google.com/webstore/detail/simplify-gmail/pbmlfaiicoikhdbjagjbglnbfcbcojpj"
        target="_new"
      >
        <div className="browserLogo" />
        Add to Chrome
      </a>
      <a
        className="button brave"
        href="https://chrome.google.com/webstore/detail/simplify-gmail/pbmlfaiicoikhdbjagjbglnbfcbcojpj"
        target="_new"
      >
        <div className="browserLogo" />
        Add to Brave
      </a>
      <a className="button firefox" href="https://addons.mozilla.org/firefox/addon/simplifygmail/" target="_new">
        <div className="browserLogo" />
        Add to Firefox
      </a>
      <a
        className="button edge"
        href="https://microsoftedge.microsoft.com/addons/detail/mpohnjhkncdalhjdhpiphajhiahmcapn"
        target="_new"
      >
        <div className="browserLogo" />
        Add to Edge
      </a>
      <a className="button safari" href="https://apps.apple.com/app/id1544668450" target="_new">
        <div className="browserLogo" />
        Add to Safari
      </a>

      {/* ALSO AVAILABLE FOR */}
      <p>Also available for:</p>
      <ul className="browserList">
        <li className="chrome">
          <a
            href="https://chrome.google.com/webstore/detail/simplify-gmail/pbmlfaiicoikhdbjagjbglnbfcbcojpj"
            target="_new"
          >
            <div className="browserLogo" />
            Chrome
          </a>
        </li>
        <li className="firefox">
          <a href="https://addons.mozilla.org/firefox/addon/simplifygmail/" target="_new">
            <div className="browserLogo" />
            Firefox
          </a>
        </li>
        <li className="edge">
          <a href="https://microsoftedge.microsoft.com/addons/detail/mpohnjhkncdalhjdhpiphajhiahmcapn" target="_new">
            <div className="browserLogo" />
            Edge
          </a>
        </li>
        <li className="safari">
          <a href="https://apps.apple.com/app/id1544668450" target="_new">
            <div className="browserLogo" />
            Safari
          </a>
        </li>
        <li className="arc">
          <a
            href="https://chrome.google.com/webstore/detail/simplify-gmail/pbmlfaiicoikhdbjagjbglnbfcbcojpj"
            target="_new"
          >
            <div className="browserLogo" />
            Arc
          </a>
        </li>
        <li className="brave">
          <a
            href="https://chrome.google.com/webstore/detail/simplify-gmail/pbmlfaiicoikhdbjagjbglnbfcbcojpj"
            target="_new"
          >
            <div className="browserLogo" />
            Brave
          </a>
        </li>
        <li className="vivaldi">
          <a
            href="https://chrome.google.com/webstore/detail/simplify-gmail/pbmlfaiicoikhdbjagjbglnbfcbcojpj"
            target="_new"
          >
            <div className="browserLogo" />
            Vivaldi
          </a>
        </li>
      </ul>

      {/* LINK TO CANARY */}
      {/* <br />
      <br />
      <hr />
      <h2>Canary Channel</h2>
      <p>
        Install Simplify Gmail's{" "}
        <a
          href="https://chrome.google.com/webstore/detail/simplify-gmail-canary/kgmfphgflhecbmihdpgamkkgienkllif/"
          target="_new"
        >
          Canary version
        </a>{" "}
        (Chrome Web Store only) to get the latest changes first and provide input on the future of Simplify Gmail.
      </p> */}
    </Content>
  );
}
