// Why doesn't this work?
export default function hash(str) {
  let string = str.toLowerCase();

  // Strip out periods from username if an email
  if (string.indexOf("@") > -1) {
    string = string.split("@");
    string = string[0].replace(/\./g, "") + "@" + string[1];
  }

  // Hash the string
  let hash = 0,
    i = 0,
    len = string.length;
  while (i < len) {
    hash = ((hash << 5) - hash + string.charCodeAt(i++)) << 0;
  }

  // Add biggest number to make it possitive
  return hash + 2147483647 + 1;
}
