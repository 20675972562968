import React from "react";
import Subscription from "./Subscription";

export default function Subscriptions({ subscriptions, fs }) {
  return (
    <>
      {subscriptions &&
      Object.entries(subscriptions).filter(
        ([key, sub]) => !sub.active && sub.people === undefined
      ).length > 0
        ? "Activating subscription. This can take a minute or two."
        : Object.keys(subscriptions).map((sid) => (
            <Subscription
              subscription={subscriptions[sid]}
              sid={sid}
              key={sid}
              peopleCount={
                subscriptions[sid].active && subscriptions[sid].people
                  ? Object.keys(subscriptions[sid].people).length
                  : 1
              }
              fs={fs}
            />
          ))}
    </>
  );
}
