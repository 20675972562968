import React, { useEffect } from "react";
import { emojiFlag } from "../utils/prices";
import "../styles/selectCountry.css";

export default function SelectCountry({ id, defaultValue, onChange, size }) {
  // Update flag when country is changed
  useEffect(() => {
    document.getElementById(
      id
    ).style.backgroundImage = `url(https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/${emojiFlag[defaultValue]}.svg), url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23222222%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")`;
  }, [defaultValue, id]);

  return (
    <div id="countrySelect" className={size}>
      <label htmlFor={id}>Country:</label>
      <select id={id} defaultValue={defaultValue} onChange={onChange}>
        <option value="AR">Argentina</option>
        <option value="AU">Australia</option>
        <option value="AT">Austria</option>
        <option value="BH">Bahrain</option>
        <option value="BB">Barbados</option>
        <option value="BY">Belarus</option>
        <option value="BE">Belgium</option>
        <option value="BM">Bermuda</option>
        <option value="BO">Bolivia</option>
        <option value="BA">Bosnia and Herzegovina</option>
        <option value="BR">Brazil</option>
        <option value="BG">Bulgaria</option>
        <option value="CA">Canada</option>
        <option value="KY">Cayman Islands</option>
        <option value="CL">Chile</option>
        <option value="CN">China</option>
        <option value="CO">Colombia</option>
        <option value="CR">Costa Rica</option>
        <option value="HR">Croatia</option>
        <option value="CY">Cyprus</option>
        <option value="CZ">Czech Republic</option>
        <option value="DK">Denmark</option>
        <option value="DO">Dominican Republic</option>
        <option value="EC">Ecuador</option>
        <option value="EG">Egypt</option>
        <option value="SV">El Salvador</option>
        <option value="EE">Estonia</option>
        <option value="FI">Finland</option>
        <option value="FR">France</option>
        <option value="PF">French Polynesia</option>
        <option value="DE">Germany</option>
        <option value="GR">Greece</option>
        <option value="GP">Guadeloupe</option>
        <option value="GU">Guam</option>
        <option value="GT">Guatemala</option>
        <option value="GG">Guernsey</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hungary</option>
        <option value="IS">Iceland</option>
        <option value="IN">India</option>
        <option value="ID">Indonesia</option>
        <option value="IE">Ireland</option>
        <option value="IM">Isle of Man</option>
        <option value="IL">Israel</option>
        <option value="IT">Italy</option>
        <option value="JM">Jamaica</option>
        <option value="JP">Japan</option>
        <option value="JE">Jersey</option>
        <option value="JO">Jordan</option>
        <option value="KZ">Kazakhstan</option>
        <option value="KE">Kenya</option>
        <option value="KW">Kuwait</option>
        <option value="LV">Latvia</option>
        <option value="LB">Lebanon</option>
        <option value="LI">Liechtenstein</option>
        <option value="LT">Lithuania</option>
        <option value="LU">Luxembourg</option>
        <option value="MY">Malaysia</option>
        <option value="MT">Malta</option>
        <option value="MQ">Martinique</option>
        <option value="MU">Mauritius</option>
        <option value="MX">Mexico</option>
        <option value="MC">Monaco</option>
        <option value="MA">Morocco</option>
        <option value="NL">Netherlands</option>
        <option value="NC">New Caledonia</option>
        <option value="NZ">New Zealand</option>
        <option value="NG">Nigeria</option>
        <option value="NO">Norway</option>
        <option value="OM">Oman</option>
        <option value="PK">Pakistan</option>
        <option value="PA">Panama</option>
        <option value="PE">Peru</option>
        <option value="PH">Philippines</option>
        <option value="PL">Poland</option>
        <option value="PT">Portugal</option>
        <option value="PR">Puerto Rico</option>
        <option value="QA">Qatar</option>
        <option value="RO">Romania</option>
        <option value="RU">Russia</option>
        <option value="SM">San Marino</option>
        <option value="SA">Saudi Arabia</option>
        <option value="RS">Serbia</option>
        <option value="SG">Singapore</option>
        <option value="SK">Slovakia</option>
        <option value="SI">Slovenia</option>
        <option value="ZA">South Africa</option>
        <option value="KR">South Korea</option>
        <option value="ES">Spain</option>
        <option value="SE">Sweden</option>
        <option value="CH">Switzerland</option>
        <option value="TW">Taiwan</option>
        <option value="TH">Thailand</option>
        <option value="TT">Trinidad and Tobago</option>
        <option value="TR">Turkey</option>
        <option value="UA">Ukraine</option>
        <option value="AE">United Arab Emirates</option>
        <option value="GB">United Kingdom</option>
        <option value="US">United States</option>
        <option value="VI">US Virgin Islands</option>
        <option value="UY">Uruguay</option>
        <option value="VE">Venezuela</option>
        <option value="VN">Vietnam</option>
      </select>
    </div>
  );
}
