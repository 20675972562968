import React from "react";
import { useAuth } from "../contexts/AuthContext";
import ButtonIcon from "./ButtonIcon";

export default function Email({ email, pid, sid }) {
  const { deleteEmail } = useAuth();

  function handleDeleteEmail() {
    deleteEmail(sid, pid, email).catch((error) => {
      console.log("Could not delete email", error);
    });
  }

  return (
    <div className="email">
      {email}
      <ButtonIcon
        action="remove"
        hide="true"
        title="Remove email"
        onClick={handleDeleteEmail}
      />
    </div>
  );
}
