import React from "react";
import { useAuth } from "../contexts/AuthContext";
import defaultAvatar from "../assets/icons/profile.svg";

import "../styles/buttonProfile.css";

export default function ButtonProfile({ ...otherProps }) {
  const { currentUser } = useAuth();

  return (
    <div id="account" {...otherProps}>
      {currentUser?.photoURL ? (
        <img src={currentUser.photoURL} alt="" />
      ) : (
        <img src={defaultAvatar} alt="" />
      )}
    </div>
  );
}
