import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { functions, httpsCallable } from "../utils/firebase";
import { useAuth } from "../contexts/AuthContext";
import Person from "./Person";
import Input from "./Input";
import ButtonIcon from "./ButtonIcon";
import Dialog from "./Dialog";
import Button from "./Button";
import SelectCountry from "./SelectCountry";
import { localPrices, formatPrice, getLocalPrices } from "../utils/prices";

export default function Subscription({ subscription, sid, peopleCount, fs }) {
  const [newPersonName, setNewPersonName] = useState("");
  const [error, setError] = useState("");
  const [applyUpdate, setApplyUpdate] = useState("Update");
  const [country, setCountry] = useState("US");
  const [urlEmail, setUrlEmail] = useState("");
  const [, product, term, seats] = subscription.active ? subscription.product.split("-") : ["", "Gmail", "annual", 1];
  const [newSeats, setNewSeats] = useState(parseInt(seats));

  // Pricing variables
  const [upgradeCost, setUpgradeCost] = useState(0);
  const [renewalCost, setRenewalCost] = useState(0);
  const [prices, setPrices] = useState(localPrices[country] || localPrices["US"]);

  // Other vars
  const history = useHistory();
  const productProper = subscription.active
    ? product.charAt(0).toUpperCase() + product.substr(1).toLowerCase()
    : "Gmail";
  const usedSeats = Object.keys(subscription.people).length;
  const expiresLong = new Date(subscription.expires).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const { addPerson, currentUser } = useAuth();

  // select all the text on focus
  function handleFocus(e) {
    e.target.select();
  }

  // Handle the seats number changing
  function handleSeatsChange(e) {
    if (e.target.value !== "") {
      setNewSeats(parseInt(e.target.value));
    } else {
      setNewSeats("");
    }
  }

  // Handle increasing / decreasing group seats
  function increaseSeats() {
    if (newSeats <= 50) setNewSeats(newSeats + 1);
  }
  function decreaseSeats() {
    if (newSeats > usedSeats) setNewSeats(newSeats - 1);
  }

  function changeSeats() {
    document.getElementById("showChangeSeats").classList.add("hidden");
    document.getElementById("changeSeats").classList.remove("hidden");
    document.getElementById("applyUpdate").classList.remove("hidden");
  }

  async function getPrices(country = "US") {
    await getLocalPrices(country).then((results) => {
      setPrices(results);
    });
  }

  function manageSubscription() {
    getPrices(country);
    setNewSeats(parseInt(seats));
    document.getElementById("showChangeSeats")?.classList.remove("hidden");
    document.getElementById("applyUpdate")?.classList.add("hidden");
    document.getElementById("changeSeats")?.classList.add("hidden");
    document.getElementById("modifySub")?.classList.add("show");
  }

  function handleAddPerson(e) {
    e.preventDefault();
    setError("");

    // Make sure new person's name is not an email address
    if (/^\S+@\S+\.\S{2,}$/.test(newPersonName)) {
      window.alert(`Person's name appears to be an email address: ${newPersonName}`);
      setNewPersonName("");
      return;
    }

    // Make sure we have a name and there are open seats
    if (peopleCount >= seats) {
      console.log("Person NOT added. Seats all full.");
      document.activeElement.closest(".addPerson")?.classList.add("hidden");
      document.activeElement.blur();
      return;
    }

    // Update database
    addPerson(sid, newPersonName)
      .then(() => {
        setNewPersonName("");

        // Hide the add person input if at max
        if (peopleCount >= parseInt(seats) - 1) {
          document.activeElement.closest(".addPerson")?.classList.add("hidden");
          document.activeElement.blur();
        }
      })
      .catch((error) => {
        setError("Could not add person");
      });
  }

  function handleChangePayment() {
    document.querySelector("#modifySub").classList.remove("show");
    document.querySelector("#loading").classList.add("show");
    setTimeout(() => {
      document.querySelector("#loading").classList.remove("show");
    }, 5000);

    // TODO: Cache this url and have it expire after 24 hours
    const getSubscriptionLink = httpsCallable(functions, "getSubscriptionLink");
    getSubscriptionLink({ fs: fs }).then(async (result) => {
      if (result.data.url) {
        window.open(result.data.url + "#/subscriptions");
        document.querySelector("#loading").classList.remove("show");
      } else {
        document.querySelector("#loading").classList.remove("show");
        console.error("Did not get back data", result.data.error);
      }
    });
  }

  async function handleChangeAutoRenew() {
    document.getElementById("modifySub").classList.remove("show");
    document.getElementById("loading").classList.add("show");

    const changeAutoRenew = httpsCallable(functions, "changeAutoRenew");
    const plan = { uid: currentUser.uid, sid: sid, renew: !subscription.renew };

    await changeAutoRenew(plan).then(async (response) => {
      if (response.data.result) {
        console.log("Success!", response.data.result);
      } else {
        console.log("Failure!", response.data.error);
      }
      document.getElementById("loading").classList.remove("show");
    });
  }

  async function handleChangeSeats() {
    document.getElementById("modifySub").classList.remove("show");
    document.getElementById("loading").classList.add("show");

    const prorate = newSeats > seats ? true : false;

    const planChanges = {
      uid: currentUser.uid,
      sid: sid,
      term: term,
      seats: newSeats,
      prorate: prorate,
      change: "seats",
    };

    const changePlan = httpsCallable(functions, "changePlan");
    await changePlan(planChanges).then(async (response) => {
      if (response.data.result) {
        console.log("Success!", response.data.result);
      } else {
        console.log("Failure!", response.data.error);
      }
      document.getElementById("loading").classList.remove("show");
    });
  }

  async function handleChangeTerm() {
    document.getElementById("modifySub").classList.remove("show");
    document.getElementById("loading").classList.add("show");

    const planChanges = {
      uid: currentUser.uid,
      sid: sid,
      term: term === "annual" ? "monthly" : "annual",
      seats: seats,
      prorate: false,
      change: "term",
    };

    const changePlan = httpsCallable(functions, "changePlan");
    await changePlan(planChanges).then(async (response) => {
      if (response.data.result) {
        console.log("Success!", response.data.result);
      } else {
        console.log("Failure!", response.data.error);
      }
      document.getElementById("loading").classList.remove("show");
    });
  }

  // Toggle the add button when newPersonName is edited
  useEffect(() => {
    const addButton = document.activeElement?.nextElementSibling;
    if (addButton) {
      if (newPersonName !== "") {
        addButton.classList.add("on");
      } else {
        addButton.classList.remove("on");
      }
    }
  }, [newPersonName]);

  // Make sure newSeats stays in bounds
  useEffect(() => {
    if (newSeats > 50) {
      setNewSeats(50);
    } else if (newSeats < usedSeats) {
      setNewSeats(usedSeats);
    }

    if (parseInt(newSeats) <= parseInt(seats)) {
      const multiplier = term === "annual" ? 12 : 1;
      setUpgradeCost(multiplier * prices[term + "-" + newSeats]);
      setApplyUpdate("Update");
    } else {
      const multiplier = term === "annual" ? 12 : 1;
      const planDiff = multiplier * (prices[term + "-" + newSeats] - prices[term + "-" + seats]);
      const daysLeft = subscription.expires - new Date();
      const daysPlan = term === "annual" ? 31536000000 : 2678400000;
      const prorated = planDiff * (daysLeft / daysPlan);
      setUpgradeCost(prorated);
      setRenewalCost(multiplier * prices[term + "-" + newSeats]);
      setApplyUpdate("Pay & Update");
    }

    // TODO: if changing to monthly plan, notify that change will take place at next renewal

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSeats, usedSeats, prices]);

  // Update prices if country is changed
  useEffect(() => {
    getPrices(country);
  }, [country]);

  // On load, check if we're here to modify the subscription
  useEffect(() => {
    if (window.localStorage.modifySub) {
      manageSubscription();
      window.localStorage.removeItem("modifySub");
      window.localStorage.removeItem("seats");
      window.localStorage.removeItem("term");
    }

    // Expand person if there is only one person on the plan
    // if (Object.keys(subscription.people).length === 1) {
    document.querySelector('button[action="zip"]')?.setAttribute("expanded", "true");
    // }

    // Look to see if there is an email address to add to the subscription in the URL
    const email = new URLSearchParams(window.location.search).get("addEmail");
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (email) {
      if (validEmail.test(email)) {
        if (Object.keys(subscription.people).length === 1) {
          setUrlEmail(email);
        } else {
          alert(`Add ${email} to one of the people to add to your plan`);
        }
      }
      history.push("/account");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {subscription.active && (
        <>
          <div className="subscription box">
            <div>
              <b>
                Simplify {productProper} ({seats} {seats > 1 ? <>people</> : <>person</>})
              </b>
              <span>
                {subscription.renew
                  ? "Renews on "
                  : subscription.expires < Date.now() + 60000
                  ? "Ended on "
                  : "Ends on "}
                {expiresLong}
              </span>
              <span className="link" onClick={manageSubscription}>
                Modify plan
              </span>
            </div>
          </div>

          {/* <div className="details"></div> */}

          <div className="people box">
            {/* COLUMN HEADERS */}
            <div className="colHeaders">
              <div>PEOPLE</div>
              <div>EMAILS</div>
            </div>

            {/* LIST PEOPLE ON PLAN */}
            {subscription.people
              ? Object.keys(subscription.people).map((pid) => (
                  <Person
                    person={subscription.people[pid]}
                    sid={sid}
                    pid={pid}
                    key={pid}
                    exp={subscription.expires}
                    urlEmail={urlEmail}
                  />
                ))
              : ""}

            {Object.keys(subscription.people).length < seats ? (
              <div className="addPerson">
                <ButtonIcon action="addPerson" disabled />
                <form onSubmit={handleAddPerson}>
                  {error && <p>{error}</p>}
                  <Input
                    type="text"
                    value={newPersonName}
                    className="stealth small"
                    required
                    placeholder="Add person"
                    handleChange={(e) => setNewPersonName(e.target.value)}
                  />
                  <ButtonIcon type="submit" action="add" hide="true" />
                </form>
              </div>
            ) : (
              <div className="addStuff">
                <span className="link" onClick={manageSubscription}>
                  Upgrade plan to add more people
                </span>
              </div>
            )}
          </div>

          <div className="box tips">
            <div>
              <b>How to use Simplify Gmail:</b>
              <br />
              1. Add people and their email addresses to your plan above
              <br />
              2. Tell each person to <a href="/">install Simplify</a>
              <br />
              3. Enjoy a better Gmail!
            </div>
          </div>

          <Dialog id="modifySub" title="Modify plan">
            <table>
              <tbody>
                <tr>
                  <td>Current plan</td>
                  <td>
                    Simplify {productProper} ({seats} {seats > 1 ? <>people</> : <>person</>}, {seats - usedSeats}{" "}
                    unused)
                    <span className="link" id="showChangeSeats" onClick={changeSeats}>
                      Add / remove seats
                    </span>
                    <div id="changeSeats" className="row vcenter inputify hidden">
                      <ButtonIcon action="decrease" onClick={decreaseSeats} />
                      <input
                        id="newSeats"
                        value={newSeats}
                        type="number"
                        min={usedSeats}
                        max="50"
                        step="1"
                        onFocus={handleFocus}
                        onClick={handleFocus}
                        onChange={(e) => handleSeatsChange(e)}
                      />
                      <label htmlFor="newSeats">people</label>
                      <ButtonIcon action="increase" onClick={increaseSeats} />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Payment method</td>
                  <td>
                    {subscription.pay}
                    <span className="link" onClick={handleChangePayment}>
                      Change on FastSpring
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>{subscription.renew ? "Renews on" : "Ends on"}</td>
                  <td>
                    {expiresLong} {term === "annual" ? " for 1 year" : "for 1 month"}
                    <span id="changeTerm" className="link" onClick={handleChangeTerm}>
                      Switch{" "}
                      {term === "annual" ? (
                        <>
                          to monthly payments <span className="fineprint">($1/mo more)</span>
                        </>
                      ) : (
                        <>
                          to annual payments <span className="fineprint">(save $12/yr)</span>
                        </>
                      )}
                    </span>
                    {subscription.renew ? (
                      <span className="link red" onClick={handleChangeAutoRenew}>
                        Disable auto-renewal
                      </span>
                    ) : (
                      <span className="link" onClick={handleChangeAutoRenew}>
                        Enable auto renew
                      </span>
                    )}
                  </td>
                </tr>

                <tr className="divider">
                  <td colSpan="2"></td>
                </tr>

                <tr id="applyUpdate" className="update hidden">
                  <td>
                    {newSeats > seats ? (
                      <>
                        Due today: {formatPrice(country, upgradeCost)}{" "}
                        {["US", "CA"].includes(country) && <span className="fineprint uppercase">(+Tax)</span>}
                        <br />
                        <span className="fineprint">
                          New plan rate: {formatPrice(country, renewalCost)}
                          {term === "annual" ? "/yr" : "/mo"}
                        </span>
                      </>
                    ) : (
                      <>
                        On renewal: {formatPrice(country, upgradeCost)}{" "}
                        {["US", "CA"].includes(country) && <span className="fineprint uppercase">(+Tax)</span>}
                      </>
                    )}
                  </td>
                  <td className="vcenter">
                    <Button
                      id="updatePlan"
                      disabled={parseInt(newSeats) === parseInt(seats)}
                      onClick={handleChangeSeats}
                    >
                      {applyUpdate}
                    </Button>
                    <SelectCountry
                      id="country"
                      defaultValue={country}
                      onChange={(e) => setCountry(e.target.value)}
                      size="small"
                    />
                  </td>
                </tr>
                <tr className="fineprint">
                  <td colSpan="2">
                    Plan payments all related payment information securely managed by{" "}
                    <a href="https://fastspring.com" target="_new">
                      FastSpring
                    </a>
                    , an authorized reseller. Charges will appear on your bill as ”FS* simpl.fyi.”
                  </td>
                </tr>
              </tbody>
            </table>
          </Dialog>
        </>
      )}
    </>
  );
}
