import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import Email from "./Email";
import Input from "./Input";
import ButtonIcon from "./ButtonIcon";

export default function Person({ person, sid, pid, exp, urlEmail }) {
  const [newEmail, setNewEmail] = useState("");
  const [newName, setNewName] = useState(person.name);
  const [isExpanded, setIsExpanded] = useState("false");
  const emailCount = person.emails ? Object.keys(person.emails).length : 0;
  const { addEmail, updateName, deletePerson } = useAuth();

  function focusAddEmail(e) {
    // Expand person
    setIsExpanded("true");

    // Focus email input (delay 10ms for the input to become unhidden or React won't focus it)
    setTimeout(() => {
      e.target.closest(".person").querySelector(".addEmail input").focus();
    }, 10);
  }

  function handleUpdateName(e) {
    e.preventDefault();

    if (/^\S+@\S+\.\S{2,}$/.test(newName)) {
      window.alert(`Person's name appears to be an email address: ${newName}`);
      return;
    }

    updateName(sid, pid, newName)
      .then(() => {
        console.log("Saved name", newName);
        document.querySelector("button.on")?.classList.remove("on");

        // Remove focus from name field
        document.activeElement.blur();
      })
      .catch((error) => {
        console.log("Could not save name", error);
      });
  }

  function handleAddEmail(e) {
    e.preventDefault();

    // Clean up email
    let email = cleanEmail(newEmail);

    addEmail(sid, pid, email, exp)
      .then(() => {
        setIsExpanded("true");
        setNewEmail("");
        focusAddEmail(e);
      })
      .catch((error) => {
        // TODO: Create a better error state
        if (error.message.indexOf("Permission denied") > -1) {
          window.alert(
            `${newEmail} is already on an active plan. Can not add.`
          );
        }
      });
  }

  function cleanEmail(email) {
    let cleanEmail = email.toLowerCase();

    // Double check for + in email
    let plusRegex = /\+.*@/;
    if (plusRegex.test(cleanEmail)) {
      cleanEmail = cleanEmail.replace(plusRegex, "@");
    }

    // Look for anything after "gmail.com"
    let gmailRegex = /@gmail.com.+/;
    if (gmailRegex.test(cleanEmail)) {
      cleanEmail = cleanEmail.replace(gmailRegex, "@gmail.com");
    }

    return cleanEmail;
  }

  function handleDeletePerson(e) {
    const people = e.target.closest(".people").querySelectorAll(".person");

    if (people.length === 1) {
      window.alert(
        "Can not remove last person from plan. You can change their name or emails though."
      );
      return;
    }

    // TODO: Confirm they want to remove the person if they have emails
    if (person.emails && Object.keys(person.emails).length > 0) {
      const areYouSure = window.confirm(
        `Remove ${newName} and their emails from the subscription? This can not be undone.`
      );
      if (!areYouSure) return;
    }

    const emails = person.emails ? Object.keys(person.emails) : false;
    deletePerson(sid, pid, emails).catch((error) => {
      console.log("Could not delete person", error);
    });
  }

  // Toggle the save button when name is edited
  useEffect(() => {
    const input = document.activeElement;
    if (input.getAttribute("name") === "personName") {
      const saveButton = input.nextElementSibling;
      if (newName !== person.name) {
        saveButton.classList.add("on");
      } else {
        saveButton.classList.remove("on");
      }
    }
  }, [newName, person.name]);

  // Toggle the add button when email is edited
  useEffect(() => {
    const addButton = document.activeElement?.nextElementSibling;
    if (addButton) {
      if (newEmail !== "") {
        addButton.classList.add("on");
      } else {
        addButton.classList.remove("on");
      }
    }
  }, [newEmail]);

  // Catch email submitted as url param and add
  useEffect(() => {
    if (urlEmail) {
      const confirmAdd = window.confirm(`Add ${urlEmail} to your plan?`);
      if (confirmAdd) {
        let email = cleanEmail(urlEmail);
        addEmail(sid, pid, email, exp).catch((error) => {
          if (error.message.indexOf("Permission denied") > -1) {
            window.alert(
              `${urlEmail} is already on an active plan. Can not add.`
            );
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [urlEmail]);

  return (
    <div className="person">
      <ButtonIcon
        action="zip"
        expanded={isExpanded}
        onClick={(e) => setIsExpanded(isExpanded === "true" ? "false" : "true")}
      />
      <div className="name">
        <form onSubmit={handleUpdateName}>
          <Input
            name="personName"
            className="stealth small"
            value={newName}
            required
            onChange={(e) => setNewName(e.target.value)}
          />
          <ButtonIcon type="submit" action="save" hide="true" />
        </form>
      </div>
      <div className="break"></div>

      <div className={emailCount === 0 ? "emails none" : "emails"}>
        <div className="one">
          {emailCount === 1 &&
            Object.keys(person.emails).map((eid) => (
              <Email email={person.emails[eid]} pid={pid} sid={sid} key={eid} />
            ))}

          {emailCount > 1 && (
            <div className="email many" onClick={() => setIsExpanded("true")}>
              {emailCount} emails
            </div>
          )}
        </div>

        <div className="all">
          {emailCount > 0 &&
            Object.keys(person.emails).map((eid) => (
              <Email email={person.emails[eid]} pid={pid} sid={sid} key={eid} />
            ))}
        </div>

        {emailCount < 10 && (
          <div className="addEmail">
            <form onSubmit={handleAddEmail}>
              <Input
                type="email"
                value={newEmail}
                className="small stealth"
                required
                placeholder={"Add email for " + person.name}
                handleChange={(e) =>
                  setNewEmail(e.target.value.replace(/\+.*@/, "@"))
                }
              />
              <ButtonIcon type="submit" action="add" hide="true" />
            </form>
          </div>
        )}
      </div>
      {emailCount < 10 && (
        <>
          <ButtonIcon
            action="addEmail"
            title={"Add email for " + person.name}
            alt={"Add email for " + person.name}
            addClass="desktopOnly"
            onClick={focusAddEmail}
          />
          <span className="link mobileOnly" onClick={focusAddEmail}>
            Add email
          </span>
        </>
      )}
      <ButtonIcon
        action="delete"
        alt={"Remove " + person.name}
        title={"Remove " + person.name}
        addClass="desktopOnly"
        onClick={handleDeletePerson}
      />
      <span className="link mobileOnly" onClick={handleDeletePerson}>
        Delete person
      </span>
    </div>
  );
}
