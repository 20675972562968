import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import ButtonProfile from "./ButtonProfile";
import "../styles/header.css";
import Logo from "../assets/logo/simplify.svg";

export default function Header() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  function toggleAccountMenu() {
    document.querySelector(".links .menu").classList.toggle("show");
    document.querySelector(".links").classList.toggle("menuOpen");
  }

  function handleLogout() {
    logout()
      .then(() => {
        history.push("/");
        document.querySelector(".links").classList.remove("menuOpen");
      })
      .catch((error) => {
        console.log("Failed to log out", error.message);
      });
  }

  function goHome() {
    history.push("/");
    window.scrollTo(0, 0);
  }

  return (
    <header>
      <img src={Logo} id="logo" alt="Simplify" onClick={goHome} />
      <div className="links">
        <Link to="/about">About</Link>
        <Link to="/support">Support</Link>
        {currentUser ? (
          <>
            <Link to="/gmail">Install</Link>
            <Link to="/account">My Plan</Link>
            <ButtonProfile onClick={toggleAccountMenu} />
            <div className="menu">
              {currentUser.displayName ? (
                <>
                  <div className="name">{currentUser.displayName}</div>
                  <div className="address">{currentUser.email}</div>
                </>
              ) : (
                <div className="name">{currentUser.email}</div>
              )}
              <Link to="/edit-login" className="button small light">
                Edit login
              </Link>
              <div className="button small light" onClick={handleLogout}>
                Sign out
              </div>
            </div>
            <div className="tempScrim" onClick={toggleAccountMenu}></div>
          </>
        ) : (
          <>
            <Link to="/plans">Plans</Link>
            <Link to="/login">Sign in</Link>
            <Link to="/gmail">Install</Link>
          </>
        )}
      </div>
    </header>
  );
}
