import { functions, httpsCallable } from "./firebase";

export const localPrices = {
  US: {
    "annual-1": 2,
    "annual-2": 3,
    "annual-3": 4,
    "annual-4": 5,
    "annual-5": 6,
    "annual-6": 7,
    "annual-7": 8,
    "annual-8": 9,
    "annual-9": 10,
    "annual-10": 10.75,
    "annual-11": 11.5,
    "annual-12": 12.25,
    "annual-13": 13,
    "annual-14": 13.75,
    "annual-15": 14.5,
    "annual-16": 15.25,
    "annual-17": 16,
    "annual-18": 16.75,
    "annual-19": 17.5,
    "annual-20": 18.25,
    "annual-21": 19,
    "annual-22": 19.75,
    "annual-23": 20.5,
    "annual-24": 21.25,
    "annual-25": 21.75,
    "annual-26": 22.25,
    "annual-27": 22.75,
    "annual-28": 23.25,
    "annual-29": 23.75,
    "annual-30": 24.25,
    "annual-31": 24.75,
    "annual-32": 25.25,
    "annual-33": 25.75,
    "annual-34": 26.25,
    "annual-35": 26.75,
    "annual-36": 27.25,
    "annual-37": 27.75,
    "annual-38": 28.25,
    "annual-39": 28.75,
    "annual-40": 29.25,
    "annual-41": 29.75,
    "annual-42": 30.25,
    "annual-43": 30.75,
    "annual-44": 31.25,
    "annual-45": 31.75,
    "annual-46": 32.25,
    "annual-47": 32.75,
    "annual-48": 33.25,
    "annual-49": 33.75,
    "annual-50": 34.25,
    "annual-51": 34.75,
    "annual-52": 35.25,
    "annual-53": 35.75,
    "annual-54": 36.25,
    "annual-55": 36.75,
    "annual-56": 37.25,
    "annual-57": 37.75,
    "annual-58": 38.25,
    "annual-59": 38.75,
    "annual-60": 39.25,
    "annual-61": 39.75,
    "annual-62": 40.25,
    "annual-63": 40.75,
    "annual-64": 41.25,
    "annual-65": 41.75,
    "annual-66": 42.25,
    "annual-67": 42.75,
    "annual-68": 43.25,
    "annual-69": 43.75,
    "annual-70": 44.25,
    "annual-71": 44.75,
    "annual-72": 45.25,
    "annual-73": 45.75,
    "annual-74": 46.25,
    "annual-75": 46.75,
    "annual-76": 47.25,
    "annual-77": 47.75,
    "annual-78": 48.25,
    "annual-79": 48.75,
    "annual-80": 49.25,
    "annual-81": 49.75,
    "annual-82": 50.25,
    "annual-83": 50.75,
    "annual-84": 51.25,
    "annual-85": 51.75,
    "annual-86": 52.25,
    "annual-87": 52.75,
    "annual-88": 53.25,
    "annual-89": 53.75,
    "annual-90": 54.25,
    "annual-91": 54.75,
    "annual-92": 55.25,
    "annual-93": 55.75,
    "annual-94": 56.25,
    "annual-95": 56.75,
    "annual-96": 57.25,
    "annual-97": 57.75,
    "annual-98": 58.25,
    "annual-99": 58.75,
    "annual-100": 59.25,
    "monthly-1": 3,
    "monthly-2": 4,
    "monthly-3": 5,
    "monthly-4": 6,
    "monthly-5": 7,
    "monthly-6": 8,
    "monthly-7": 9,
    "monthly-8": 10,
    "monthly-9": 11,
    "monthly-10": 11.75,
    "monthly-11": 12.5,
    "monthly-12": 13.25,
    "monthly-13": 14,
    "monthly-14": 14.75,
    "monthly-15": 15.5,
    "monthly-16": 16.25,
    "monthly-17": 17,
    "monthly-18": 17.75,
    "monthly-19": 18.5,
    "monthly-20": 19.25,
    "monthly-21": 20,
    "monthly-22": 20.75,
    "monthly-23": 21.5,
    "monthly-24": 22.25,
    "monthly-25": 22.75,
    "monthly-26": 23.25,
    "monthly-27": 23.75,
    "monthly-28": 24.25,
    "monthly-29": 24.75,
    "monthly-30": 25.25,
    "monthly-31": 25.75,
    "monthly-32": 26.25,
    "monthly-33": 26.75,
    "monthly-34": 27.25,
    "monthly-35": 27.75,
    "monthly-36": 28.25,
    "monthly-37": 28.75,
    "monthly-38": 29.25,
    "monthly-39": 29.75,
    "monthly-40": 30.25,
    "monthly-41": 30.75,
    "monthly-42": 31.25,
    "monthly-43": 31.75,
    "monthly-44": 32.25,
    "monthly-45": 32.75,
    "monthly-46": 33.25,
    "monthly-47": 33.75,
    "monthly-48": 34.25,
    "monthly-49": 34.75,
    "monthly-50": 35.25,
    "monthly-51": 35.75,
    "monthly-52": 36.25,
    "monthly-53": 36.75,
    "monthly-54": 37.25,
    "monthly-55": 37.75,
    "monthly-56": 38.25,
    "monthly-57": 38.75,
    "monthly-58": 39.25,
    "monthly-59": 39.75,
    "monthly-60": 40.25,
    "monthly-61": 40.75,
    "monthly-62": 41.25,
    "monthly-63": 41.75,
    "monthly-64": 42.25,
    "monthly-65": 42.75,
    "monthly-66": 43.25,
    "monthly-67": 43.75,
    "monthly-68": 44.25,
    "monthly-69": 44.75,
    "monthly-70": 45.25,
    "monthly-71": 45.75,
    "monthly-72": 46.25,
    "monthly-73": 46.75,
    "monthly-74": 47.25,
    "monthly-75": 47.75,
    "monthly-76": 48.25,
    "monthly-77": 48.75,
    "monthly-78": 49.25,
    "monthly-79": 49.75,
    "monthly-80": 50.25,
    "monthly-81": 50.75,
    "monthly-82": 51.25,
    "monthly-83": 51.75,
    "monthly-84": 52.25,
    "monthly-85": 52.75,
    "monthly-86": 53.25,
    "monthly-87": 53.75,
    "monthly-88": 54.25,
    "monthly-89": 54.75,
    "monthly-90": 55.25,
    "monthly-91": 55.75,
    "monthly-92": 56.25,
    "monthly-93": 56.75,
    "monthly-94": 57.25,
    "monthly-95": 57.75,
    "monthly-96": 58.25,
    "monthly-97": 58.75,
    "monthly-98": 59.25,
    "monthly-99": 59.75,
    "monthly-100": 60.25,
  },
};

const useComma = [
  "PR",
  "AM",
  "AO",
  "AR",
  "BA",
  "RS",
  "BD",
  "BM",
  "BQ",
  "SD",
  "BW",
  "SJ",
  "SS",
  "SV",
  "SZ",
  "CM",
  "CN",
  "CU",
  "CY",
  "TN",
  "TR",
  "DJ",
  "DZ",
  "EG",
  "ER",
  "ES",
  "VN",
  "FM",
  "GP",
  "HM",
  "HT",
  "YE",
  "IL",
  "IR",
  "JM",
  "KN",
  "LR",
  "LT",
  "NG",
  "NI",
  "PH",
];

export const supportedCountries = [
  "AR",
  "AU",
  "AT",
  "BH",
  "BB",
  "BY",
  "BE",
  "BM",
  "BO",
  "BA",
  "BR",
  "BG",
  "CA",
  "KY",
  "CL",
  "CN",
  "CO",
  "CR",
  "HR",
  "CY",
  "CZ",
  "DK",
  "DO",
  "EC",
  "EG",
  "SV",
  "EE",
  "FI",
  "FR",
  "PF",
  "DE",
  "GR",
  "GP",
  "GU",
  "GT",
  "GG",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KW",
  "LV",
  "LB",
  "LI",
  "LT",
  "LU",
  "MY",
  "MT",
  "MQ",
  "MU",
  "MX",
  "MC",
  "MA",
  "NL",
  "NC",
  "NZ",
  "NG",
  "NO",
  "OM",
  "PK",
  "PA",
  "PE",
  "PH",
  "PL",
  "PT",
  "PR",
  "QA",
  "RO",
  "RU",
  "SM",
  "SA",
  "RS",
  "SG",
  "SK",
  "SI",
  "ZA",
  "KR",
  "ES",
  "SE",
  "CH",
  "TW",
  "TH",
  "TT",
  "TR",
  "UA",
  "AE",
  "GB",
  "US",
  "VI",
  "UY",
  "VE",
  "VN",
];

export const supportedCurrency = [
  "USD",
  "EUR",
  "GBP",
  "AUD",
  "CAD",
  "NZD",
  "CHF",
  "SEK",
  "CZK",
  "DKK",
  "PLN",
  "SGD",
  "JPY",
  "CNY",
  "HKD",
  "INR",
  "BRL",
  "MXN",
  "COP",
  "CLP",
  "KRW",
];

export const differentPrice = [
  "AD",
  "AE",
  "AT",
  "AU",
  "AX",
  "BA",
  "BE",
  "BG",
  "BL",
  "BR",
  "CA",
  "CC",
  "CH",
  "CK",
  "CL",
  "CN",
  "CO",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FO",
  "FR",
  "GB",
  "GE",
  "GF",
  "GG",
  "GL",
  "GP",
  "GR",
  "GS",
  "HK",
  "HM",
  "HR",
  "HU",
  "IC",
  "IE",
  "IM",
  "IN",
  "IS",
  "IT",
  "JE",
  "JP",
  "KI",
  "KR",
  "LI",
  "LT",
  "LU",
  "LV",
  "MC",
  "ME",
  "MF",
  "MQ",
  "MT",
  "MX",
  "NF",
  "NL",
  "NO",
  "NR",
  "NU",
  "NZ",
  "PL",
  "PM",
  "PN",
  "PT",
  "RE",
  "RO",
  "RS",
  "RU",
  "SA",
  "SE",
  "SG",
  "SI",
  "SK",
  "SM",
  "TF",
  "TK",
  "TR",
  "TV",
  "TW",
  "VA",
  "YT",
  "ZA",
];

export const emojiFlag = {
  AR: "1f1e6-1f1f7",
  AU: "1f1e6-1f1fa",
  AT: "1f1e6-1f1f9",
  BH: "1f1e7-1f1ed",
  BB: "1f1e7-1f1e7",
  BY: "1f1e7-1f1fe",
  BE: "1f1e7-1f1ea",
  BM: "1f1e7-1f1f2",
  BO: "1f1e7-1f1f4",
  BA: "1f1e7-1f1e6",
  BR: "1f1e7-1f1f7",
  BG: "1f1e7-1f1ec",
  CA: "1f1e8-1f1e6",
  KY: "1f1f0-1f1fe",
  CL: "1f1e8-1f1f1",
  CN: "1f1e8-1f1f3",
  CO: "1f1e8-1f1f4",
  CR: "1f1e8-1f1f7",
  HR: "1f1ed-1f1f7",
  CY: "1f1e8-1f1fe",
  CZ: "1f1e8-1f1ff",
  DK: "1f1e9-1f1f0",
  DO: "1f1e9-1f1f4",
  EC: "1f1ea-1f1e8",
  EG: "1f1ea-1f1ec",
  SV: "1f1f8-1f1fb",
  EE: "1f1ea-1f1ea",
  FI: "1f1eb-1f1ee",
  FR: "1f1eb-1f1f7",
  PF: "1f1f5-1f1eb",
  DE: "1f1e9-1f1ea",
  GR: "1f1ec-1f1f7",
  GP: "1f1ec-1f1f5",
  GU: "1f1ec-1f1fa",
  GT: "1f1ec-1f1f9",
  GG: "1f1ec-1f1ec",
  HK: "1f1ed-1f1f0",
  HU: "1f1ed-1f1fa",
  IS: "1f1ee-1f1f8",
  IN: "1f1ee-1f1f3",
  ID: "1f1ee-1f1e9",
  IE: "1f1ee-1f1ea",
  IM: "1f1ee-1f1f2",
  IL: "1f1ee-1f1f1",
  IT: "1f1ee-1f1f9",
  JM: "1f1ef-1f1f2",
  JP: "1f1ef-1f1f5",
  JE: "1f1ef-1f1ea",
  JO: "1f1ef-1f1f4",
  KZ: "1f1f0-1f1ff",
  KE: "1f1f0-1f1ea",
  KW: "1f1f0-1f1fc",
  LV: "1f1f1-1f1fb",
  LB: "1f1f1-1f1e7",
  LI: "1f1f1-1f1ee",
  LT: "1f1f1-1f1f9",
  LU: "1f1f1-1f1fa",
  MY: "1f1f2-1f1fe",
  MT: "1f1f2-1f1f9",
  MQ: "1f1f2-1f1f6",
  MU: "1f1f2-1f1fa",
  MX: "1f1f2-1f1fd",
  MC: "1f1f2-1f1e8",
  MA: "1f1f2-1f1e6",
  NL: "1f1f3-1f1f1",
  NC: "1f1f3-1f1e8",
  NZ: "1f1f3-1f1ff",
  NG: "1f1f3-1f1ec",
  NO: "1f1f3-1f1f4",
  OM: "1f1f4-1f1f2",
  PK: "1f1f5-1f1f0",
  PA: "1f1f5-1f1e6",
  PE: "1f1f5-1f1ea",
  PH: "1f1f5-1f1ed",
  PL: "1f1f5-1f1f1",
  PT: "1f1f5-1f1f9",
  PR: "1f1f5-1f1f7",
  QA: "1f1f6-1f1e6",
  RO: "1f1f7-1f1f4",
  RU: "1f1f7-1f1fa",
  SM: "1f1f8-1f1f2",
  SA: "1f1f8-1f1e6",
  RS: "1f1f7-1f1f8",
  SG: "1f1f8-1f1ec",
  SK: "1f1f8-1f1f0",
  SI: "1f1f8-1f1ee",
  ZA: "1f1ff-1f1e6",
  KR: "1f1f0-1f1f7",
  ES: "1f1ea-1f1f8",
  SE: "1f1f8-1f1ea",
  CH: "1f1e8-1f1ed",
  TW: "1f1f9-1f1fc",
  TH: "1f1f9-1f1ed",
  TT: "1f1f9-1f1f9",
  TR: "1f1f9-1f1f7",
  UA: "1f1fa-1f1e6",
  AE: "1f1e6-1f1ea",
  GB: "1f1ec-1f1e7",
  US: "1f1fa-1f1f8",
  VI: "1f1fb-1f1ee",
  UY: "1f1fa-1f1fe",
  VE: "1f1fb-1f1ea",
  VN: "1f1fb-1f1f3",
};

export const localCurrency = {
  AD: { cur: "EUR", pre: "€", post: "" },
  AE: { cur: "USD", pre: "$", post: "" },
  AF: { cur: "USD", pre: "$", post: "" },
  AG: { cur: "USD", pre: "US$", post: "" },
  AI: { cur: "USD", pre: "US$", post: "" },
  AL: { cur: "USD", pre: "$", post: "" },
  AM: { cur: "USD", pre: "$", post: "" },
  AO: { cur: "USD", pre: "US$", post: "" },
  AQ: { cur: "USD", pre: "$", post: "" },
  AR: { cur: "USD", pre: "US$", post: "" },
  AS: { cur: "USD", pre: "$", post: "" },
  AT: { cur: "EUR", pre: "€", post: "" },
  AU: { cur: "AUD", pre: "$", post: "" },
  AW: { cur: "USD", pre: "$", post: "" },
  AX: { cur: "EUR", pre: "€", post: "" },
  AZ: { cur: "USD", pre: "$", post: "" },
  BA: { cur: "EUR", pre: "€", post: "" },
  BB: { cur: "USD", pre: "US$", post: "" },
  BD: { cur: "USD", pre: "$", post: "" },
  BE: { cur: "EUR", pre: "€", post: "" },
  BF: { cur: "USD", pre: "$", post: "" },
  BG: { cur: "EUR", pre: "€", post: "" },
  BH: { cur: "USD", pre: "$", post: "" },
  BI: { cur: "USD", pre: "$", post: "" },
  BJ: { cur: "USD", pre: "$", post: "" },
  BL: { cur: "EUR", pre: "€", post: "" },
  BM: { cur: "USD", pre: "US$", post: "" },
  BN: { cur: "USD", pre: "$", post: "" },
  BO: { cur: "USD", pre: "US$", post: "" },
  BQ: { cur: "USD", pre: "$", post: "" },
  BR: { cur: "BRL", pre: "R$", post: "" },
  BS: { cur: "USD", pre: "US$", post: "" },
  BT: { cur: "USD", pre: "$", post: "" },
  BV: { cur: "USD", pre: "$", post: "" },
  BW: { cur: "USD", pre: "US$", post: "" },
  BY: { cur: "USD", pre: "$", post: "" },
  BZ: { cur: "USD", pre: "US$", post: "" },
  CA: { cur: "CAD", pre: "$", post: "" },
  CC: { cur: "AUD", pre: "$", post: "" },
  CD: { cur: "USD", pre: "$", post: "" },
  CF: { cur: "USD", pre: "$", post: "" },
  CG: { cur: "USD", pre: "$", post: "" },
  CH: { cur: "CHF", pre: "CHF", post: "" },
  CI: { cur: "USD", pre: "$", post: "" },
  CK: { cur: "NZD", pre: "$", post: "" },
  CL: { cur: "CLP", pre: "$", post: "" },
  CM: { cur: "USD", pre: "US$", post: "" },
  CN: { cur: "CNY", pre: "￥", post: "" },
  CO: { cur: "COP", pre: "$", post: "" },
  CR: { cur: "USD", pre: "US$", post: "" },
  CU: { cur: "USD", pre: "$", post: "" },
  CV: { cur: "USD", pre: "US$", post: "" },
  CW: { cur: "USD", pre: "$", post: "" },
  CX: { cur: "AUD", pre: "$", post: "" },
  CY: { cur: "EUR", pre: "€", post: "" },
  CZ: { cur: "CZK", pre: "", post: " Kč" },
  DE: { cur: "EUR", pre: "€", post: "" },
  DJ: { cur: "USD", pre: "$", post: "" },
  DK: { cur: "DKK", pre: "", post: " kr." },
  DM: { cur: "USD", pre: "US$", post: "" },
  DO: { cur: "USD", pre: "US$", post: "" },
  DZ: { cur: "USD", pre: "$", post: "" },
  EC: { cur: "USD", pre: "$", post: "" },
  EE: { cur: "EUR", pre: "€", post: "" },
  EG: { cur: "USD", pre: "$", post: "" },
  EH: { cur: "USD", pre: "$", post: "" },
  ER: { cur: "USD", pre: "US$", post: "" },
  ES: { cur: "EUR", pre: "€", post: "" },
  ET: { cur: "USD", pre: "$", post: "" },
  FI: { cur: "EUR", pre: "€", post: "" },
  FJ: { cur: "USD", pre: "US$", post: "" },
  FK: { cur: "USD", pre: "US$", post: "" },
  FM: { cur: "USD", pre: "US$", post: "" },
  FO: { cur: "DKK", pre: "DKK", post: "" },
  FR: { cur: "EUR", pre: "€", post: "" },
  GA: { cur: "USD", pre: "$", post: "" },
  GB: { cur: "GBP", pre: "£", post: "" },
  GD: { cur: "USD", pre: "US$", post: "" },
  GE: { cur: "EUR", pre: "€", post: "" },
  GF: { cur: "EUR", pre: "€", post: "" },
  GG: { cur: "GBP", pre: "£", post: "" },
  GH: { cur: "USD", pre: "US$", post: "" },
  GI: { cur: "USD", pre: "US$", post: "" },
  GL: { cur: "DKK", pre: "DKK", post: "" },
  GM: { cur: "USD", pre: "US$", post: "" },
  GN: { cur: "USD", pre: "$", post: "" },
  GP: { cur: "EUR", pre: "€", post: "" },
  GQ: { cur: "USD", pre: "$", post: "" },
  GR: { cur: "EUR", pre: "€", post: "" },
  GS: { cur: "GBP", pre: "£", post: "" },
  GT: { cur: "USD", pre: "US$", post: "" },
  GU: { cur: "USD", pre: "$", post: "" },
  GW: { cur: "USD", pre: "$", post: "" },
  GY: { cur: "USD", pre: "US$", post: "" },
  HK: { cur: "HKD", pre: "HK$", post: "" },
  HM: { cur: "AUD", pre: "A$", post: "" },
  HN: { cur: "USD", pre: "$", post: "" },
  HR: { cur: "EUR", pre: "€", post: "" },
  HT: { cur: "USD", pre: "$", post: "" },
  HU: { cur: "EUR", pre: "€", post: "" },
  ID: { cur: "USD", pre: "$", post: "" },
  IE: { cur: "EUR", pre: "€", post: "" },
  IL: { cur: "USD", pre: "US$", post: "" },
  IM: { cur: "GBP", pre: "£", post: "" },
  IN: { cur: "INR", pre: "₹", post: "" },
  IO: { cur: "USD", pre: "US$", post: "" },
  IQ: { cur: "USD", pre: "$", post: "" },
  IR: { cur: "USD", pre: "$", post: "" },
  IS: { cur: "EUR", pre: "€", post: "" },
  IT: { cur: "EUR", pre: "€", post: "" },
  JE: { cur: "GBP", pre: "£", post: "" },
  JM: { cur: "USD", pre: "US$", post: "" },
  JO: { cur: "USD", pre: "$", post: "" },
  JP: { cur: "JPY", pre: "¥", post: "" },
  KE: { cur: "USD", pre: "US$", post: "" },
  KG: { cur: "USD", pre: "$", post: "" },
  KH: { cur: "USD", pre: "$", post: "" },
  KI: { cur: "AUD", pre: "$", post: "" },
  KM: { cur: "USD", pre: "$", post: "" },
  KN: { cur: "USD", pre: "US$", post: "" },
  KP: { cur: "USD", pre: "$", post: "" },
  KR: { cur: "KRW", pre: "₩", post: "" },
  KW: { cur: "USD", pre: "$", post: "" },
  KY: { cur: "USD", pre: "US$", post: "" },
  KZ: { cur: "USD", pre: "$", post: "" },
  LA: { cur: "USD", pre: "$", post: "" },
  LB: { cur: "USD", pre: "$", post: "" },
  LC: { cur: "USD", pre: "US$", post: "" },
  LI: { cur: "CHF", pre: "CHF", post: "" },
  LK: { cur: "USD", pre: "$", post: "" },
  LR: { cur: "USD", pre: "US$", post: "" },
  LS: { cur: "USD", pre: "US$", post: "" },
  LT: { cur: "EUR", pre: "€", post: "" },
  LU: { cur: "EUR", pre: "€", post: "" },
  LV: { cur: "EUR", pre: "€", post: "" },
  LY: { cur: "USD", pre: "$", post: "" },
  MA: { cur: "USD", pre: "$", post: "" },
  MC: { cur: "EUR", pre: "€", post: "" },
  MD: { cur: "USD", pre: "$", post: "" },
  ME: { cur: "EUR", pre: "€", post: "" },
  MF: { cur: "EUR", pre: "€", post: "" },
  MG: { cur: "USD", pre: "US$", post: "" },
  MH: { cur: "USD", pre: "$", post: "" },
  MK: { cur: "USD", pre: "$", post: "" },
  ML: { cur: "USD", pre: "$", post: "" },
  MM: { cur: "USD", pre: "$", post: "" },
  MN: { cur: "USD", pre: "$", post: "" },
  MO: { cur: "USD", pre: "US$", post: "" },
  MP: { cur: "USD", pre: "$", post: "" },
  MQ: { cur: "EUR", pre: "€", post: "" },
  MR: { cur: "USD", pre: "$", post: "" },
  MS: { cur: "USD", pre: "US$", post: "" },
  MT: { cur: "EUR", pre: "€", post: "" },
  MU: { cur: "USD", pre: "US$", post: "" },
  MV: { cur: "USD", pre: "$", post: "" },
  MW: { cur: "USD", pre: "US$", post: "" },
  MX: { cur: "MXN", pre: "$", post: "" },
  MY: { cur: "USD", pre: "US$", post: "" },
  MZ: { cur: "USD", pre: "$", post: "" },
  NA: { cur: "USD", pre: "US$", post: "" },
  NC: { cur: "USD", pre: "$", post: "" },
  NE: { cur: "USD", pre: "$", post: "" },
  NF: { cur: "AUD", pre: "$", post: "" },
  NG: { cur: "USD", pre: "US$", post: "" },
  NI: { cur: "USD", pre: "$", post: "" },
  NL: { cur: "EUR", pre: "€", post: "" },
  NO: { cur: "EUR", pre: "€", post: "" },
  NP: { cur: "USD", pre: "$", post: "" },
  NR: { cur: "AUD", pre: "$", post: "" },
  NU: { cur: "NZD", pre: "$", post: "" },
  NZ: { cur: "NZD", pre: "$", post: "" },
  OM: { cur: "USD", pre: "$", post: "" },
  PA: { cur: "USD", pre: "US$", post: "" },
  PE: { cur: "USD", pre: "US$", post: "" },
  PF: { cur: "USD", pre: "$", post: "" },
  PG: { cur: "USD", pre: "US$", post: "" },
  PH: { cur: "USD", pre: "US$", post: "" },
  PK: { cur: "USD", pre: "US$", post: "" },
  PL: { cur: "PLN", pre: "", post: " zł" },
  PM: { cur: "EUR", pre: "€", post: "" },
  PN: { cur: "NZD", pre: "$", post: "" },
  PR: { cur: "USD", pre: "$", post: "" },
  PS: { cur: "USD", pre: "$", post: "" },
  PT: { cur: "EUR", pre: "€", post: "" },
  PW: { cur: "USD", pre: "US$", post: "" },
  PY: { cur: "USD", pre: "$", post: "" },
  QA: { cur: "USD", pre: "$", post: "" },
  RE: { cur: "EUR", pre: "€", post: "" },
  RO: { cur: "EUR", pre: "€", post: "" },
  RS: { cur: "EUR", pre: "€", post: "" },
  RU: { cur: "USD", pre: "$", post: "" },
  RW: { cur: "USD", pre: "US$", post: "" },
  SA: { cur: "USD", pre: "$", post: "" },
  SB: { cur: "USD", pre: "US$", post: "" },
  SC: { cur: "USD", pre: "US$", post: "" },
  SD: { cur: "USD", pre: "US$", post: "" },
  SE: { cur: "SEK", pre: "", post: " kr." },
  SG: { cur: "SGD", pre: "$", post: "" },
  SH: { cur: "USD", pre: "US$", post: "" },
  SI: { cur: "EUR", pre: "€", post: "" },
  SJ: { cur: "USD", pre: "$", post: "" },
  SK: { cur: "EUR", pre: "€", post: "" },
  SL: { cur: "USD", pre: "US$", post: "" },
  SM: { cur: "EUR", pre: "€", post: "" },
  SN: { cur: "USD", pre: "$", post: "" },
  SO: { cur: "USD", pre: "$", post: "" },
  SR: { cur: "USD", pre: "$", post: "" },
  SS: { cur: "USD", pre: "US$", post: "" },
  ST: { cur: "USD", pre: "US$", post: "" },
  SV: { cur: "USD", pre: "$", post: "" },
  SX: { cur: "USD", pre: "US$", post: "" },
  SY: { cur: "USD", pre: "$", post: "" },
  SZ: { cur: "USD", pre: "US$", post: "" },
  TC: { cur: "USD", pre: "US$", post: "" },
  TD: { cur: "USD", pre: "$", post: "" },
  TF: { cur: "EUR", pre: "€", post: "" },
  TG: { cur: "USD", pre: "$", post: "" },
  TH: { cur: "USD", pre: "$", post: "" },
  TJ: { cur: "USD", pre: "$", post: "" },
  TK: { cur: "NZD", pre: "$", post: "" },
  TL: { cur: "USD", pre: "$", post: "" },
  TM: { cur: "USD", pre: "$", post: "" },
  TN: { cur: "USD", pre: "$", post: "" },
  TO: { cur: "USD", pre: "US$", post: "" },
  TR: { cur: "USD", pre: "$", post: "" },
  TT: { cur: "USD", pre: "US$", post: "" },
  TV: { cur: "AUD", pre: "$", post: "" },
  TW: { cur: "USD", pre: "US$", post: "" },
  TZ: { cur: "USD", pre: "US$", post: "" },
  UA: { cur: "USD", pre: "$", post: "" },
  UG: { cur: "USD", pre: "US$", post: "" },
  UM: { cur: "USD", pre: "$", post: "" },
  US: { cur: "USD", pre: "$", post: "" },
  UY: { cur: "USD", pre: "US$", post: "" },
  UZ: { cur: "USD", pre: "$", post: "" },
  VA: { cur: "EUR", pre: "€", post: "" },
  VC: { cur: "USD", pre: "US$", post: "" },
  VE: { cur: "USD", pre: "US$", post: "" },
  VG: { cur: "USD", pre: "US$", post: "" },
  VI: { cur: "USD", pre: "$", post: "" },
  VN: { cur: "USD", pre: "$", post: "" },
  VU: { cur: "USD", pre: "US$", post: "" },
  WF: { cur: "USD", pre: "$", post: "" },
  WS: { cur: "USD", pre: "US$", post: "" },
  YE: { cur: "USD", pre: "$", post: "" },
  YT: { cur: "EUR", pre: "€", post: "" },
  ZA: { cur: "USD", pre: "US$", post: "" },
  ZM: { cur: "USD", pre: "US$", post: "" },
  ZW: { cur: "USD", pre: "US$", post: "" },
};

// Get prices from cache or FastSpring
export async function getLocalPrices(country = "US") {
  window.localStorage.country = country;

  // Are prices cached somewhere?
  if (localPrices[country]) {
    return localPrices[country];
  } else {
    if (window.localStorage.localPrices && window.localStorage.pricesExpire) {
      const cachedPrices = JSON.parse(window.localStorage.localPrices);
      if (window.localStorage.pricesExpire > Date.now() && cachedPrices[country]) {
        return cachedPrices[country];
      }
    }
  }

  // If prices not cached, get from FastSpring
  const callGetPrices = httpsCallable(functions, "getPrices");
  return await callGetPrices({ country: country }).then(async (results) => {
    if (results.data) {
      let cachedPrices = {};
      if (window.localStorage.localPrices && window.localStorage.pricesExpire > Date.now()) {
        cachedPrices = JSON.parse(window.localStorage.localPrices);
      } else {
        window.localStorage.pricesExpire = Date.now() + 86400000;
      }
      cachedPrices[country] = results.data;
      window.localStorage.localPrices = JSON.stringify(cachedPrices);

      return results.data;
    } else {
      console.error(`Did not get back local prices for ${country}`);
      return localPrices["US"];
    }
  });
}

// Add currency symbol and remove trailing ".00"
export function formatPrice(country, amount) {
  // Does country use . or , in numbers?
  let price = useComma.includes(country)
    ? parseFloat(amount).toFixed(2).toString().replace(".", ",")
    : parseFloat(amount).toFixed(2);

  // Remove cents if zero
  price = price.replace(/\.00|,00$/, "");

  // Add currency symbol and return
  return localCurrency[country].pre + price + localCurrency[country].post;
}

// Detect browser's language
export function getLang() {
  const browserLang = navigator.language.substring(0, 2);
  const fastSpringLangs = [
    "ar",
    "cs",
    "da",
    "de",
    "en",
    "es",
    "fi",
    "fr",
    "hr",
    "it",
    "ja",
    "ko",
    "nl",
    "nn",
    "no",
    "pl",
    "pt",
    "ru",
    "sk",
    "sv",
    "tr",
    "zh",
  ];

  if (fastSpringLangs.includes(browserLang)) {
    return browserLang;
  } else {
    return "en";
  }
}
