import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { functions, httpsCallable } from "../utils/firebase";
import Content from "../components/Content";
import Button from "../components/Button";
import ButtonIcon from "../components/ButtonIcon";
import SelectCountry from "../components/SelectCountry";
import { localPrices, localCurrency, formatPrice, supportedCountries, getLocalPrices, getLang } from "../utils/prices";

import "../styles/plans.css";

export default function Plans() {
  const history = useHistory();
  const { currentUser, account, loadAccount, initSubscription } = useAuth();

  const [seats, setSeats] = useState(window.localStorage.seats ? parseInt(window.localStorage.seats) : 2);
  const [term, setTerm] = useState(window.localStorage.term || "annual");
  const [country, setCountry] = useState(window.localStorage.country || "US");
  const [currency, setCurrency] = useState(localCurrency[country].cur);
  const [prices, setPrices] = useState(localPrices[country] || {});
  const [checkoutBtn, setCheckoutBtn] = useState("Continue");
  const [priceSingle, setPriceSingle] = useState(prices[term + "-1"] || 2);
  const [priceGroup, setPriceGroup] = useState(prices[term + "-" + seats] || 3);
  const [accountLang, setAccountLang] = useState(window.localStorage.accountLang || getLang());
  const detailsRef = useRef();

  // HANDLE INPUTS & HELPERS
  function handleCardClick(e) {
    const card = e.target.classList.contains("box") ? e.target : e.target.closest(".box");

    if (card) {
      document.querySelectorAll(".box.selected").forEach((card) => card.classList.remove("selected"));
      card.classList.add("selected");

      if (card.id === "individual") {
        window.localStorage.setItem("seats", 1);
      } else if (card.id === "group") {
        window.localStorage.setItem("seats", seats);
      }
    }
  }

  // select all the text on focus
  function handleFocus(e) {
    e.target.select();
  }

  // Handle the seats number changing
  function handleSeatsChange(e) {
    if (e.target.value !== "") {
      setSeats(parseInt(e.target.value));
    } else {
      setSeats("");
    }
  }

  // Handle increasing / decreasing group seats
  function increaseSeats() {
    if (seats <= 50) setSeats(seats + 1);
  }
  function decreaseSeats() {
    if (seats >= 2) setSeats(seats - 1);
  }

  function contactSales() {
    window.open(
      "mailto:hi@simpl.fyi?subject=Simplify%20enterprise%20plan&body=Please%20tell%20me%20how%20many%20people%20you%20would%20like%20to%20cover%20and%20if%20they%20are%20all%20under%20one%20or%20more%20domains."
    );
  }

  // Scroll to details
  function goToDetails() {
    window.scrollTo(0, detailsRef.current.offsetTop - 100);
  }
  function backToTop() {
    window.scrollTo(0, 0);
  }
  function zip(e) {
    e.target.closest(".zippy").classList.toggle("open");
  }

  // FASTSPRING CHECKOUT POPUP
  function checkout(e) {
    if (e) e.preventDefault();

    if (document.getElementById("enterprise").classList.contains("selected")) {
      contactSales();
      return;
    }

    if (!currentUser) {
      history.push("/login?then=plans");
      return;
    }

    let selectedSeats = localStorage.seats || seats || 1;

    let session = {
      reset: true,
      products: [
        {
          path: `simplify-gmail-${term}-${selectedSeats}`,
          quantity: 1,
        },
      ],
      paymentContact: {
        email: currentUser.email,
      },
      checkout: true,
      country: country,
      language: accountLang,
      tags: {
        accountEmail: currentUser.email,
      },
    };
    window.fastspring.builder.push(session);
  }

  function hasActiveSubscription() {
    return (
      account.subs &&
      Object.values(account.subs).filter((sub) => {
        return sub.active || (!sub.active && sub.product === undefined);
      }).length > 0
    );
  }

  // INIT CART ON LOAD
  useEffect(() => {
    document.title = "Plans for Simplify Gmail";

    if (currentUser) {
      setCheckoutBtn("Checkout");
      loadAccount();
    }

    // Initialize the prices if not already done
    if (Object.keys(prices).length === 0) {
      getPrices(country);
    }

    let selectedSeats = localStorage.seats || seats || 1;
    if (selectedSeats >= 2) {
      document.getElementById("enterprise").classList.remove("selected");
      document.getElementById("individual").classList.remove("selected");
      document.getElementById("group").classList.add("selected");
    }

    if (term === "monthly") {
      document.getElementById("monthly").setAttribute("checked", "true");
    } else {
      document.getElementById("annual").setAttribute("checked", "true");
    }

    // Initiate checkout if ?checkout=true
    if (new URLSearchParams(window.location.search).get("checkout")) {
      checkout();
    }

    // Get the country based on IP from firebase if it hasn't been changed
    if (!window.localStorage.country && country === "US") {
      const getCountry = httpsCallable(functions, "getCountry");
      getCountry({}).then(function (result) {
        if (result.data.country && supportedCountries.indexOf(result.data.country) > -1) {
          setCountry(result.data.country);
          document.getElementById("country").value = result.data.country;
          window.localStorage.country = result.data.country;
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  // BANDAID: CHECKOUT DONE BUT FASTSPRING BROKE ON PAYPAL PAYMENT?
  useEffect(() => {
    if (
      new URLSearchParams(window.location.search).get("checkout") &&
      new URLSearchParams(window.location.search).get("fscNext") === "fsc:invoke:complete"
    ) {
      window.checkoutRedirect = setTimeout(() => {
        history.push("/account");
      }, 1000);
    }

    // else if (
    //   new URLSearchParams(window.location.search).get("from") === "gmail"
    // ) {
    //   document.querySelector(".miniHeader").classList.remove("hidden");
    // }
  }, [history]);

  // ONCE ACCOUNT IS LOADED, INSPECT PLAN
  useEffect(() => {
    if (account?.fs && hasActiveSubscription()) {
      if (new URLSearchParams(window.location.search).get("checkout")) {
        window.fastspring.builder.push({ checkout: false });
      }

      // Forward to the account page to update a subscription
      history.push("/account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  // Cache chosen term
  useEffect(() => {
    window.localStorage.term = term;
  }, [term]);

  // Make sure group seats doesn't go outside of 2-50
  useEffect(() => {
    if (seats === "") {
      setSeats("");
    } else {
      if (seats > 50) {
        setSeats(50);
      } else if (seats < 2) {
        setSeats(2);
      }
      if (document.getElementById("group").classList.contains("selected")) {
        window.localStorage.seats = seats;
      }
    }
  }, [seats]);

  // Update prices when country is changed
  useEffect(() => {
    getPrices(country);
    if (country === "US") setAccountLang("en");
  }, [country]);

  // Update local version of account language so it isn't forgotten on logging in
  useEffect(() => {
    window.localStorage.setItem("accountLang", accountLang);
  }, [accountLang]);

  window.localStorage.setItem("seats", 1);

  // Update display price when term, seats, or prices are changed
  useEffect(() => {
    if (seats && seats > 0 && seats < 51) {
      setPriceSingle(formatPrice(country, prices[term + "-1"]));
      setPriceGroup(formatPrice(country, prices[term + "-" + seats]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, seats, prices]);

  // FASTSPRING CALLBACKS
  window.errorCallback = (code, string) => {
    console.error("Order error: ", code, string);
  };

  // On order completion
  window.popupWebhookReceived = async (order) => {
    if (order !== null) {
      console.log("Order received", order);

      // Browser hook fired, cancel bandaid
      if (window.checkoutRedirect) {
        clearTimeout(window.checkoutRedirect);
      }

      // Initialize the subscription and associate subscription ID with Simplify account
      initSubscription(order.items[0].subscription)
        .then(() => {
          console.log("Plan initialized!");

          // Clean up local storage
          window.localStorage.removeItem("seats");
          window.localStorage.removeItem("term");
          window.localStorage.removeItem("modifySub");
          window.localStorage.removeItem("accountLang");

          // Finally, go to Account dashboard
          history.push("/account");
        })
        .catch((error) => {
          console.log("Unable to initialize plan", error);
        });
    } else {
      console.error("Browser webhook called with null order", order);
    }
  };

  // window.onPopupClose = (order) => {
  //   if (order === null) {
  //     console.log("Popup closed: Order not placed. Stay put.");
  //   } else {
  //     console.log("Popup closed: Order succeeded. Go to account.");
  //     history.push("/account");
  //   }
  // };

  // Get local prices wrapper
  async function getPrices(country = "US") {
    await getLocalPrices(country).then((results) => {
      setPrices(results);
      setCurrency(localCurrency[country].cur);
    });
  }

  return (
    <Content center="text">
      <div id="options" className="row vcenter">
        <div className="switch">
          <input type="radio" name="term" value="annual" id="annual" onChange={() => setTerm("annual")} />
          <label htmlFor="annual" className="off">
            Pay annually
          </label>
          <input type="radio" name="term" value="monthly" id="monthly" onChange={() => setTerm("monthly")} />
          <label htmlFor="monthly" className="on">
            Pay monthly
          </label>
          <span className="highlight"></span>
        </div>

        <SelectCountry id="country" defaultValue={country} onChange={(e) => setCountry(e.target.value)} size="full" />
      </div>

      <div className="row center">
        <div id="individual" className="box selectable selected" onClick={handleCardClick}>
          <h2>Individual</h2>
          1 person, up to 10
          <br />
          email addresses
          <br />
          <h1>{priceSingle}</h1>
          <p>
            {currency} per month
            <br />
            billed {term === "annual" ? "annually" : term}
          </p>
        </div>
        <div id="group" className="box selectable" onClick={handleCardClick}>
          <h2>Group</h2>
          <div className="row vcenter inputify">
            <ButtonIcon action="decrease" onClick={decreaseSeats} />
            <input
              id="seats"
              value={seats}
              type="number"
              min="2"
              max="50"
              step="1"
              onFocus={handleFocus}
              onClick={handleFocus}
              onChange={(e) => handleSeatsChange(e)}
            />
            <label htmlFor="seats">people</label>
            <ButtonIcon action="increase" onClick={increaseSeats} />
          </div>
          <h1>{priceGroup}</h1>
          <p>
            {currency} per month
            <br />
            billed {term === "annual" ? "annually" : term}
          </p>
        </div>
        <div id="enterprise" className="box selectable" onClick={handleCardClick}>
          <h2>Enterprise</h2>
          <div>
            51+ people
            <br />
            Bulk pricing
            <br />
            Enhanced support
            <br />
          </div>
          <Button id="contactSales" onClick={contactSales}>
            Get a quote
          </Button>
        </div>
      </div>

      {/* <Button onClick={goToDetails} type="button" big="true" id="faq">FAQ</Button> */}

      {country === "US" && <input id="accountLang" value="en" type="hidden" />}
      {country !== "US" && (
        <div className="miniFooter">
          <span>
            Language for checkout and account emails:{" "}
            <select id="accountLang" defaultValue={accountLang} onChange={(e) => setAccountLang(e.target.value)}>
              <option value="ar">العربية</option>
              <option value="cs">Čeština</option>
              <option value="da">Dansk</option>
              <option value="de">Deutsch</option>
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fi">Suomi</option>
              <option value="fr">Français</option>
              <option value="hr">Hrvatski</option>
              <option value="it">Italiano</option>
              <option value="ja">日本語</option>
              <option value="ko">한국어</option>
              <option value="nl">Nederlands</option>
              <option value="no">Norsk</option>
              <option value="pl">Polski</option>
              <option value="pt">Português</option>
              <option value="ru">Русский</option>
              <option value="sk">Slovenčina</option>
              <option value="sv">Svenska</option>
              <option value="tr">Türkçe</option>
              <option value="zh">中文</option>
            </select>
          </span>
        </div>
      )}

      <Button onClick={checkout} type="submit" big="true">
        {checkoutBtn}
      </Button>

      <div className="miniFooter">
        <Link to="install">Try it for free first</Link> -{" "}
        <span onClick={goToDetails} className="link">
          Frequently asked questions
        </span>
        {country !== "US" && <p>Price shown includes VAT for your country when applicable.</p>}
      </div>

      <div className="zippy title topMargin">
        <h2 ref={detailsRef}>Frequently asked questions</h2>
      </div>

      <div className="zippy">
        <h2 onClick={zip}>Can I try it for free first?</h2>
        <p>
          <b>Yes, please do!</b> <Link to="/gmail">Install Simplify</Link> (no payment information or account required)
          and you have one month to see if Simplify is for you. After your trial, if you are still unsure, you can start
          with a monthly plan and easily switch to an annual plan once you are sure.
        </p>
      </div>

      <div className="zippy">
        <h2 onClick={zip}>What does a plan cover?</h2>
        <ul>
          <li>
            <b>Unlimited access</b>: Each person on a plan can use Simplify on unlimited computers and on up to 10 of
            their email accounts. Email accounts can be any mix of @gmail.com accounts and custom domain Google
            Workspace accounts.
          </li>
          <li>
            <b>Rapid updates</b>: Simplify is regularly updated to support Gmail’s{" "}
            <a href="https://on.simpl.fyi/p/complexity-of-simplify" target="_new">
              billions of unique configurations
            </a>{" "}
            and as Gmail introduces small and large changes.
          </li>
          <li>
            <b>Responsive support</b>: Simplify aims to respond to all support tickets within one business day and often
            responds faster.
          </li>
          <li>
            <b>Further improvements</b>: More simplifications and enhancements to come! You can see{" "}
            <a href="https://changelog.simpl.fyi" target="_new">
              recent and upcoming changes
            </a>{" "}
            or check out a{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeWG2srrXhUn9KN5RsMWvpNOHVyYx5TpIJ4kQpYhRHDalOVbQ/viewform"
              target="_new"
            >
              list of longer term ideas
            </a>{" "}
            and vote on what gets built next.
          </li>
          <li>
            <b>Private and secure</b>: Simplify is built with your privacy and security in mind, requiring the minimum
            permissions. This is in contrast to third-party email apps and most other Gmail extensions, which require
            you to give full access to your email (Simplify does not).
          </li>
        </ul>
      </div>

      <div className="zippy">
        <h2 onClick={zip}>How does Simplify make Gmail better?</h2>
        <p>
          Simplify Gmail is a browser extension that <b>boosts productivity</b>, <b>strengthens privacy</b>, and{" "}
          <b>reduces️ stress</b> by:
        </p>
        <ul>
          <li>
            <b>A better design</b>: Hundreds of improvements (small and large) to streamline, simplify, and enhance
            Gmail’s design and functionality. Hide the features you don’t use, customize the ones you do including
            setting the list and message width and fonts.
          </li>
          <li>
            <b>Bundles</b>: Group conversations with the same label or category into a single row in your inbox for
            easier bulk reading and processing.{" "}
            <a href="https://www.youtube.com/embed/f_jel2PY9O8?autoplay=1&rel=0&showinfo=0&vq=hd1080" target="_new">
              Video demo
            </a>
          </li>
          <li>
            <b>Saved searches</b>: Add commonly used searches or bookmark any message to your nav for easy access.
            Simplify includes 24 useful searches by default like Travel, Purchases, Archived, Attachments, Notes to
            self, PDFs, Photos, and more.
          </li>
          <li>
            <b>Pause inbox</b>: Pause new mail or hide the inbox entirely for deep focus. Optionally disable
            notifications when the inbox is paused or hidden.
          </li>
          <li>
            <b>Block trackers</b>: Simplify blocks over 250 email spy trackers (more than any other tracker blocker
            extension).{" "}
            <a href="https://on.simpl.fyi/p/email-trackers-are-spying-on-yousimplify" target="_new">
              Learn more
            </a>
          </li>
          <li>
            <b>Keyboard shortcuts</b>: Navigate faster with your arrow keys, Enter (to open, then to reply), and Escape
            (close a message, then to return to inbox). Use familiar shortcuts like Ctrl+A / ⌘A to select all or Ctrl+Z
            / ⌘Z to undo. Press "?" in Gmail to see all shortcuts. Also includes advanced shortcuts like [ I ] to reply
            to an intro and move the sender to BCC.
          </li>
          <li>
            <b>Dark mode</b>: Auto-switch with OS with options to invert all of Gmail’s UI and some or all messages.
          </li>
          <li>
            <b>Group by date</b>: Group messages list by date with bulk actions to one-click archive or delete.
          </li>
          <li>
            <b>Inbox zero</b>: Immerse yourself in a background of your choice or a background of the day to get when
            you reach inbox zero.
          </li>
          <li>
            <b>Auto-bcc</b>: Set addresses to add to every new message and/or reply
          </li>
          <li>
            <b>And much more...</b>
          </li>
        </ul>
      </div>

      <div className="zippy">
        <h2 onClick={zip}>Why not a one-time payment?</h2>
        <p>
          Striving to support{" "}
          <a href="https://on.simpl.fyi/p/complexity-of-simplify" target="_new">
            over a billion unique Gmail configurations
          </a>{" "}
          and keep up with Gmail's continuous changes (both visible and invisible) is a never-ending job. A one-time fee
          for a continuous service doesn't make economic sense.
        </p>

        <p>
          I’ve tried to keep the price as low as possible to maximize the affordability of Simplify over maximizing
          profits.
        </p>
        <p>
          Keep in mind that fees and taxes eat up nearly half of a monthly payment and over a third of an annual
          payment.
        </p>
      </div>

      <Button onClick={backToTop} big="true" id="backToTop">
        Sign up!
      </Button>
    </Content>
  );
}
