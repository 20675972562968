import React from "react";
import Scrim from "./Scrim";
import ButtonIcon from "./ButtonIcon";
import "../styles/dialog.css";

export default function Dialog({ id, title, children }) {
  return (
    <Scrim id={id} dismisable="true">
      <div className="dialog">
        <div className="title">
          {title}
          <ButtonIcon
            action="close"
            onClick={() => {
              document.getElementById(id).classList.remove("show");
            }}
          />
        </div>
        <div>{children}</div>
      </div>
    </Scrim>
  );
}
