import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";

// Components
import Header from "../components/Header";
import Home from "../pages/Home";
import About from "../pages/About";
import Privacy from "../pages/Privacy";
import Support from "../pages/Support";
import Plans from "../pages/Plans";
import Gmail from "../pages/Gmail";
import Login from "../pages/Login";
import Recover from "../pages/Recover";
import Account from "../pages/Account";
import AccountEdit from "../pages/AccountEdit";

// CSS
import "../styles/app.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/support" component={Support} />

          <Route path="/plans" component={Plans} />
          <Route path="/signup">
            <Redirect to="/plans" />
          </Route>

          <Route path="/gmail" component={Gmail} />
          <Route path="/install">
            <Redirect to="/gmail" />
          </Route>

          <Route path="/login" component={Login} />
          <Route path="/recover" component={Recover} />

          <PrivateRoute path="/account" component={Account} />
          <PrivateRoute path="/subscription">
            <Redirect to="/account" />
          </PrivateRoute>
          <PrivateRoute path="/edit-login" component={AccountEdit} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
