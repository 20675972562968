import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Content from "../components/Content";
import Input from "../components/Input";
import Button from "../components/Button";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [googleError, setGoogleError] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    currentUser,
    getLoginMethod,
    login,
    loginWithGoogle,
    loginWithEmailStart,
    loginWithEmailFinish,
    isLoginLink,
  } = useAuth();

  const redirect = new URLSearchParams(window.location.search).get("then");
  const path = redirect || "account" + window.location.search;
  const checkout = path === "plans" ? "?checkout=true" : "";

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setError("");
      setMessage("");
      setLoading(true);

      // Strip out +anything from email
      let newEmail = email;
      let plusRegex = /\+.*@/;
      if (plusRegex.test(newEmail)) {
        newEmail = email.replace(plusRegex, "@");
      }

      // if (email.indexOf("+") > -1) {
      //   setError("Please do not use + in your email");
      //   setLoading(false);
      //   return;
      // }

      // Signin if email and password already entered
      if (password) {
        await login(email, password);
        history.push(`/${path}${checkout}`);
        setLoading(false);
        return;
      }

      if (isLoginLink(window.location.href)) {
        await loginWithEmailFinish(email, window.location.href);
        history.push(`/${path}${checkout}`);
        setLoading(false);
        return;
      }

      // Find out prefered method of signing in
      const loginMethod = await getLoginMethod(email);
      const usePassword = loginMethod.includes("password");
      // const usePassword = false;

      if (usePassword && email) {
        // Show password field, hide everything else
        document.querySelector("#password").classList.remove("hidden");
        document.querySelector("#password").required = "true";
        document.querySelector("#forgotPassword").classList.remove("hidden");
        setLoading(false);
        return;
      }

      // If they typed in an address that they used Google to sign in with, ask them to use that
      // const useGoogle = loginMethod.includes("google.com");
      // if (useGoogle) {
      //   handleGoogleLogin(email);
      //   setLoading(false);
      //   return;
      // }

      if (isLoginLink(window.location.href)) {
        await loginWithEmailFinish(email, window.location.href);
        history.push(`/${path}${checkout}`);
      } else {
        await loginWithEmailStart(email, window.location.href);
        setMessage(`Verification sign-in link sent to ${email}`);
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to login");
      console.log(error.message);
    }
  };

  const handleGoogleLogin = async (email = null) => {
    try {
      setGoogleError("");
      const userEmail = typeof email === "object" ? null : email;
      await loginWithGoogle(userEmail);
      history.push(`/${path}${checkout}`);
    } catch (error) {
      setGoogleError("Failed to login");
      console.log(error);
    }
  };

  // Redirect to account if already logged in
  useEffect(() => {
    // Signed in, go to Account
    if (currentUser) {
      history.push(`/${path}${checkout}`);
      return;
    }

    // Not signed in, sign in with link?
    const loginEmail = window.localStorage.getItem("emailForLogin");
    if (isLoginLink(window.location.href)) {
      if (loginEmail) {
        loginWithEmailFinish(loginEmail, window.location.href)
          .then(window.localStorage.removeItem("emailForLogin"))
          .then(history.push(`/${path}${checkout}`));
      } else {
        // Get email address to complete sign in
        setMessage("Re-enter your email address");
      }
    }

    // eslint-disable-next-line
  }, [currentUser]);

  // Hide the login button if no email entered
  useEffect(() => {
    // Signed in, go to Account
    if (email === "") {
      document.querySelector("button[type='submit']").classList.add("invisible");
    } else {
      document.querySelector("button[type='submit']").classList.remove("invisible");
    }

    // Clear error if it was a plus error
    // TODO: Just strip out the +phrase from the email
    // if (error === "Please do not use + in your email") {
    //   setError("");
    // }
  }, [email]);

  useEffect(() => {
    document.title = "Sign into Simplify Gmail account";
  }, []);

  return (
    <Content center="text">
      <p className="heading">
        {checkout === ""
          ? "Login to manage your Simplify Gmail plan"
          : "Sign up with your primary Gmail or Workspace account"}
      </p>

      <div className="mw50p">
        <Button onClick={handleGoogleLogin} className="smpl big primary google">
          Continue with Google
        </Button>
        {googleError && <p className="marginTop marginBottom">{googleError}</p>}

        <p id="or" className="topMargin bottomMargin">
          OR
        </p>

        <form onSubmit={handleSubmit}>
          <Input
            type="email"
            id="email"
            required
            value={email}
            placeholder="Enter email"
            className="smpl big"
            handleChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            id="password"
            className="smpl big hidden"
            value={password}
            placeholder="Password"
            handleChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" className="smpl primary big" disabled={loading}>
            Continue
          </Button>

          {error && <p className="marginTop marginBottom">{error}</p>}
          {message && <p className="marginTop marginBottom">{message}</p>}
          <p id="forgotPassword" className="hidden">
            <Link to="/recover">Forgot password?</Link>
          </p>
        </form>
      </div>
    </Content>
  );
}
